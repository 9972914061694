import { useState, useRef, useContext, useMemo } from "react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  PopoverHeader,
  HStack,
  Box,
  VStack,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SixPoints } from "@icon-park/react";
import CreditsHelp from "./CreditsHelp";
import { formatDateForDisplay } from "../../utils/time";
import { RivrCampaign, campaignFromQueryResult } from "../../models/rivrCampaign";
import { useQuery } from "@apollo/client";
import { OrgCampaignsQuery } from "../../api/campaign";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { AccountContext } from "../../context/AccountContext";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams } from "../../models/navigation";
import { getCurrentCycleStartDate } from "../../utils/credits";

function CreditsMenu() {
  const { memberships } = useContext(AccountContext);

  const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

  const [isCreditHelpOpen, setIsCreditHelpOpen] = useState(false);
  const onCloseCreditHelp = () => setIsCreditHelpOpen(false);
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";

  const formatCurrency = (value: number) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const { data: campaignsData } = useQuery(OrgCampaignsQuery, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { org_id: selectedMembership?.organization.id },
    // poll to update credit counts when new searches are added
    pollInterval: parseInt(process.env.REACT_APP_ACTIVE_SEARCHES_POLL_TIME || "10000"),
  });

  const campaigns: RivrCampaign[] | undefined = useMemo(() => {
    if (campaignsData) {
      return campaignsData.campaign.map((c: any) => campaignFromQueryResult(c));
    }
  }, [campaignsData]);

  const planAllotment = 100;
  const ppvHour = 1;

  const hoursThisCycle = campaigns
    ? campaigns.reduce((totalHours: number, campaign: RivrCampaign) => {
        return totalHours + campaign.hoursThisCycle;
      }, 0)
    : 0;

  const hoursLastCycle = campaigns
    ? campaigns.reduce((totalHours: number, campaign: RivrCampaign) => {
        return totalHours + campaign.hoursLastCycle;
      }, 0)
    : 0;

  const nextCycleStartDate = () => {
    const result = getCurrentCycleStartDate();
    result.setMonth(result.getMonth() + 1);
    return result;
  };

  const lastCycleEndDate = () => {
    const result = getCurrentCycleStartDate();
    result.setDate(result.getDate() - 1);
    result.setHours(23, 59, 59, 999);
    return result;
  };

  return (
    <Box>
      <Popover placement={"bottom-end"}>
        <PopoverTrigger>
          {!isMobile ? (
            <Button size={"sm"} fontWeight={"medium"} aria-label={"Video hours"}>
              <SixPoints theme="filled" />
              <Text pt={0.5} pl={1}>
                {hoursThisCycle.toLocaleString()} / {planAllotment.toLocaleString()}
              </Text>
            </Button>
          ) : (
            <Box>
              <Tooltip placement={"bottom"} label={"Video hours"}>
                <IconButton
                  size={"sm"}
                  aria-label={"Video hours"}
                  icon={<SixPoints theme="filled" />}
                />
              </Tooltip>
            </Box>
          )}
        </PopoverTrigger>
        <PopoverContent minW={"max-content"}>
          <PopoverHeader>
            <HStack w={"full"} justify={"space-between"}>
              <HStack spacing={1}>
                <SixPoints theme="filled" />
                <Text>Video hours</Text>
              </HStack>
              <Tooltip label={"Help"}>
                <IconButton
                  icon={<QuestionOutlineIcon />}
                  borderRadius={"full"}
                  size={"xs"}
                  variant={"ghost"}
                  aria-label={"Credits help"}
                  onClick={() => setIsCreditHelpOpen(true)}
                />
              </Tooltip>
            </HStack>
          </PopoverHeader>
          <PopoverBody>
            <VStack w={"full"} py={1}>
              <VStack w={"full"}>
                <VStack
                  bg={"whiteAlpha.200"}
                  w={"full"}
                  borderRadius={"md"}
                  py={2}
                  px={3}
                  spacing={-0.5}
                  borderWidth={1}
                >
                  <HStack
                    justify={"space-between"}
                    w={"full"}
                    fontSize={"xs"}
                    color={"whiteAlpha.800"}
                  >
                    <Text>Hours this month</Text>
                    <Text>Total cost</Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text>
                      {hoursThisCycle.toLocaleString()} / {planAllotment.toLocaleString()}
                    </Text>
                    <Text>
                      {formatCurrency(Math.max(0, hoursThisCycle - planAllotment) * ppvHour)}
                    </Text>
                  </HStack>
                </VStack>
              </VStack>
              {hoursLastCycle && (
                <VStack
                  bg={"whiteAlpha.200"}
                  w={"full"}
                  borderRadius={"md"}
                  py={2}
                  px={3}
                  spacing={-0.5}
                  borderWidth={1}
                >
                  <HStack
                    justify={"space-between"}
                    w={"full"}
                    fontSize={"xs"}
                    color={"whiteAlpha.800"}
                  >
                    <Text>Hours last month</Text>
                    <Text>Total cost</Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"}>
                    <Text>
                      {hoursLastCycle.toLocaleString()} / {planAllotment.toLocaleString()}
                    </Text>
                    <Text>
                      {formatCurrency(Math.max(0, hoursLastCycle - planAllotment) * ppvHour)}
                    </Text>
                  </HStack>
                </VStack>
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <CreditsHelp isOpen={isCreditHelpOpen} onClose={onCloseCreditHelp} cancelRef={cancelRef} />
    </Box>
  );
}

export default CreditsMenu;
