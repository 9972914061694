import {
  Box,
  HStack,
  IconButton,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { RivrSearch } from "../../../models/rivrSearch";
import { durationToSeconds } from "../../../utils/time";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

type InsightsStatsProps = {
  searches: RivrSearch[];
  dateFilterStart?: string;
  dateFilterEnd?: string;
  selectedChannels?: string[];
};

const InsightsStats: React.FC<InsightsStatsProps> = ({
  searches,
  dateFilterStart,
  dateFilterEnd,
  selectedChannels = [],
}) => {
  const totalDuration = useMemo(() => {
    return searches.reduce((total, search) => {
      return total + durationToSeconds(search.videoDuration);
    }, 0);
  }, [searches]);

  const totalDurationRounded = useMemo(() => {
    const hours = Math.ceil(totalDuration / 3600);
    return hours.toLocaleString();
  }, [totalDuration]);

  const uniqueChannels = useMemo(() => {
    const channels = new Set<string>();
    searches.forEach((search) => {
      if (search.metadata?.userName) {
        channels.add(search.metadata.userName);
      }
    });
    return channels.size.toLocaleString();
  }, [searches]);

  const totalViews = useMemo(() => {
    return searches
      .reduce((total, search) => {
        return total + (search.metadata?.viewCount || 0);
      }, 0)
      .toLocaleString();
  }, [searches]);

  return (
    <Box w="100%">
      <Wrap spacing={4} w="100%" justify="space-between">
        <WrapItem flex="1" alignItems={"stretch"}>
          <Stat bg="whiteAlpha.100" borderRadius="md" p={4} borderWidth={1} w="100%">
            <StatLabel>Channels</StatLabel>
            <StatNumber>{uniqueChannels}</StatNumber>
          </Stat>
        </WrapItem>

        <WrapItem flex="1" alignItems={"stretch"}>
          <Stat bg="whiteAlpha.100" borderRadius="md" p={4} borderWidth={1} w="100%">
            <StatLabel>Videos</StatLabel>
            <StatNumber>{searches.length.toLocaleString()}</StatNumber>
          </Stat>
        </WrapItem>

        <WrapItem flex="1" alignItems={"stretch"}>
          <Stat bg="whiteAlpha.100" borderRadius="md" p={4} borderWidth={1} w="100%">
            <StatLabel>Video hours</StatLabel>
            <StatNumber>{totalDurationRounded}</StatNumber>
          </Stat>
        </WrapItem>

        <WrapItem flex="1" alignItems={"stretch"}>
          <Stat bg="whiteAlpha.100" borderRadius="md" p={4} borderWidth={1} w="100%">
            <HStack w={"100%"} justify={"space-between"}>
              <StatLabel>Views</StatLabel>
              <Tooltip
                label={"Number of views a video received by the time it was analyzed."}
                placement={"bottom-end"}
              >
                <IconButton
                  aria-label={"Live views explainer"}
                  icon={<QuestionOutlineIcon />}
                  size={"xs"}
                  ml={1}
                  borderRadius={"full"}
                  variant={"ghost"}
                />
              </Tooltip>
            </HStack>
            <StatNumber>{totalViews}</StatNumber>
          </Stat>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default InsightsStats;
