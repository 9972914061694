import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  Close,
  DoubleLeft,
  DoubleRight,
  Filter,
  Left,
  Right,
  Search,
  SortThree,
} from "@icon-park/react";
import { useMemo, useState } from "react";
import CampaignSearchItem from "./CampaignSearchItem";
import "../Campaigns.css";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams, updateURLParams } from "../../../models/navigation";
import { RivrSearch, getSearchDisplayStatus } from "../../../models/rivrSearch";
import { durationToSeconds, offsetDate } from "../../../utils/time";
import { usePagination } from "../../../hooks/usePagination";
import { RivrLanguage } from "../../../models/rivrLanguage";

interface Props {
  searches: RivrSearch[];
  fetchSearches: any;
  languages: RivrLanguage[];
}

export const CampaignSearches = ({ searches, fetchSearches, languages }: Props) => {
  const DEFAULT_STATUS_SELECTION = ["analyzing", "analyzed", "reviewing", "completed", "error"];

  const [pageSize, setPageSize] = useState<number>(20);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const selectedChannels = searchParams.getAll(CampaignURLParams.SelectedChannels) || [];
  const dateFilterStart = searchParams.get(CampaignURLParams.DateFilterStart) || "";
  const dateFilterEnd = searchParams.get(CampaignURLParams.DateFilterEnd) || "";
  const itemFilterText = searchParams.get(CampaignURLParams.ItemFilterText) || "";
  const itemSortOption = searchParams.get(CampaignURLParams.ItemSortOption) || "status";
  const itemSortDirection = searchParams.get(CampaignURLParams.ItemSortDirection) || "asc";
  const selectedStatuses =
    searchParams.getAll(CampaignURLParams.SelectedStatuses) || DEFAULT_STATUS_SELECTION;
  const selectedLanguages = searchParams.getAll(CampaignURLParams.SelectedLanguages) || [];

  const handleDateFilterStart = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterStart, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleDateFilterEnd = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterEnd, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleFilterText = (text: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.ItemFilterText, text]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleItemSortOption = (option: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.ItemSortOption, option],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleItemSortDirection = (direction: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.ItemSortDirection, direction],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleStatusSelect = (selection: string | string[]) => {
    const newParams: Array<[string, string | string[]]> =
      typeof selection === "string"
        ? [[CampaignURLParams.SelectedStatuses, selection]]
        : [
            [
              CampaignURLParams.SelectedStatuses,
              selection.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              }),
            ],
          ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleLanguageSelect = (selection: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [];
    if (selection.length === 0) {
      newParams.push([CampaignURLParams.SelectedLanguages, []]);
    } else if (typeof selection === "string") {
      newParams.push([CampaignURLParams.SelectedLanguages, selection]);
    } else {
      newParams.push([
        CampaignURLParams.SelectedLanguages,
        selection.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }),
      ]);
    }
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const resetSearchFilters = () => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.DateFilterStart, ""],
      [CampaignURLParams.DateFilterEnd, ""],
      [CampaignURLParams.SelectedStatuses, DEFAULT_STATUS_SELECTION],
      [CampaignURLParams.SelectedLanguages, []],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const selectedChannelsHeaderText = useMemo(() => {
    const channelCutOff = 3; // Number of channels to display before truncating
    if (selectedChannels.length === 0) {
      return <></>;
    } else if (selectedChannels.length <= channelCutOff) {
      return (
        <Text cursor={"default"} fontWeight={"semibold"} fontSize={"xl"} color="gray.50">
          {selectedChannels.join(", ")}
        </Text>
      );
    } else {
      return (
        <Tooltip openDelay={600} label={selectedChannels.join(", ")}>
          <Text cursor={"default"} fontWeight={"semibold"} fontSize={"xl"} color="gray.50">
            {selectedChannels.slice(0, channelCutOff).join(", ")}, +
            {selectedChannels.length - channelCutOff}
          </Text>
        </Tooltip>
      );
    }
  }, [selectedChannels]);

  const selectedChannelsSearchCount = useMemo(() => {
    if (selectedChannels.length === 0) return searches.length;
    return searches.filter((s) => selectedChannels.includes(s.metadata?.userName || "")).length;
  }, [selectedChannels, searches, selectedCampaign]);

  const sortSearchData = useMemo(() => {
    return (a: RivrSearch, b: RivrSearch) => {
      // Sort archived videos to the end of the list
      if (a.archivedAt !== undefined && b.archivedAt === undefined) {
        return 1;
      } else if (a.archivedAt === undefined && b.archivedAt !== undefined) {
        return -1;
      }

      // Sort new search skeletons to the front of the list
      if (!a.metadata) {
        return -1;
      } else if (!b.metadata) {
        return 1;
      }

      if (itemSortOption === "status") {
        const statusOrder: { [key: string]: number } = {
          "in-progress": 1,
          stopped: 2,
          reviewing: 3,
          completed: 4,
          error: 5,
        };
        const orderA = statusOrder[a.workflowStatus === undefined ? a.status : a.workflowStatus];
        const orderB = statusOrder[b.workflowStatus === undefined ? b.status : b.workflowStatus];

        // Sort descending by date published if status is the same
        if ((orderA || 6) === (orderB || 6)) {
          // Put videos missing metadata (i.e. publishing date) at the end
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return itemSortDirection === "asc"
            ? (orderA || 6) - (orderB || 6)
            : (orderB || 6) - (orderA || 6);
        }
      }
      if (itemSortOption === "published") {
        // Sort any videos missing metadata (i.e. publishing date) to the end of the list
        return itemSortDirection === "asc"
          ? a.metadata.publishedAt.valueOf() - b.metadata.publishedAt.valueOf()
          : b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
      }
      if (itemSortOption === "channel") {
        if (a.metadata.userName === b.metadata.userName) {
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return itemSortDirection === "asc"
            ? a.metadata.userName.toLowerCase().localeCompare(b.metadata.userName.toLowerCase())
            : b.metadata.userName.toLowerCase().localeCompare(a.metadata.userName.toLowerCase());
        }
      }
      if (itemSortOption === "user") {
        if (a.userMoments.length === b.userMoments.length) {
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return itemSortDirection === "asc"
            ? a.userMoments.length - b.userMoments.length
            : b.userMoments.length - a.userMoments.length;
        }
      }
      if (itemSortOption === "duration") {
        if (durationToSeconds(a.videoDuration) === durationToSeconds(b.videoDuration)) {
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return itemSortDirection === "asc"
            ? durationToSeconds(a.videoDuration) - durationToSeconds(b.videoDuration)
            : durationToSeconds(b.videoDuration) - durationToSeconds(a.videoDuration);
        }
      }
      return 0;
    };
  }, [searchParams]);

  const filterSearchData = useMemo(() => {
    return (item: RivrSearch) => {
      if (item.metadata === undefined && item.status === "in-progress") {
        return true; // make sure new search skeletons are visible
      }
      if (
        selectedChannels.length > 0 &&
        selectedChannels.includes(item.metadata ? item.metadata.userName : "N/A") === false
      )
        return false;
      if (selectedStatuses.includes(getSearchDisplayStatus(item)) === false) return false;
      if (
        selectedLanguages.length > 0 &&
        selectedLanguages.includes(item.metadata ? item.metadata.language : "") === false
      )
        return false;
      const filterStartDateUTC = offsetDate(dateFilterStart);
      if (
        dateFilterStart &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() >= filterStartDateUTC.valueOf() === false
      )
        return false;
      const filterEndDateUTC = offsetDate(dateFilterEnd);
      filterEndDateUTC.setDate(filterEndDateUTC.getDate() + 1); // make the end date inclusive
      if (
        dateFilterEnd &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() < filterEndDateUTC.valueOf() === false
      )
        return false;
      if (
        item.videoTitle &&
        item.videoTitle.toLowerCase().includes(itemFilterText.toLowerCase()) === false
      )
        return false;
      return true;
    };
  }, [searchParams]);

  const {
    elements,
    totalElements,
    page,
    totalPages,
    setPage,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    from,
    to,
  } = usePagination<RivrSearch>(searches, sortSearchData, filterSearchData, pageSize);

  return searches.length > 0 ? (
    <VStack
      h={"100%"}
      w={"100%"}
      alignItems={"flex-start"}
      overflowY={"auto"}
      className={"campaign-searches"}
      spacing={0}
    >
      <HStack
        justifyContent={"space-between"}
        position={"sticky"}
        top={0}
        p={4}
        bg={"gray.800"}
        zIndex={3}
        w={"100%"}
      >
        <HStack w="100%" justifyContent={"flex-start"}>
          {selectedChannelsHeaderText}
        </HStack>
        <HStack w="100%" justifyContent={"flex-end"}>
          <HStack>
            <Text w={"100%"} noOfLines={1} fontSize={"xs"} color={"gray.400"} textAlign={"right"}>
              {totalElements > 0
                ? `Showing ${from} - ${to} of ${totalElements} ${
                    totalElements === 1 ? "video" : "videos"
                  }`
                : "Showing 0 videos"}
            </Text>
            <InputGroup size={"sm"}>
              <InputLeftElement>
                <Search />
              </InputLeftElement>
              <Input
                className={"amp-unmask"}
                type={"text"}
                size={"sm"}
                borderRadius={"md"}
                placeholder={"Filter by title"}
                value={itemFilterText}
                onChange={(e) => handleFilterText(e.target.value)}
                minW={"24rem"}
              />
              {itemFilterText && (
                <InputRightElement>
                  <IconButton
                    aria-label={"Clear text search"}
                    icon={<Close />}
                    size={"xs"}
                    variant={"ghost"}
                    borderRadius={"full"}
                    onClick={() => handleFilterText("")}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </HStack>
          <HStack>
            <Box>
              <Menu closeOnSelect={false} placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  colorScheme="gray"
                  icon={<Filter theme="filled" />}
                  size={"sm"}
                />
                <MenuList maxH={"60vh"} overflowY={"auto"} sx={{ scrollbarWidth: "thin" }}>
                  <MenuGroup title="Date published">
                    <HStack px={4} pb={2}>
                      <FormControl>
                        <Input
                          className={"amp-unmask"}
                          type={"date"}
                          size={"sm"}
                          borderRadius={"md"}
                          value={dateFilterStart}
                          onChange={(e) => handleDateFilterStart(e.target.value)}
                        />
                        <FormHelperText>From</FormHelperText>
                      </FormControl>
                      <FormControl>
                        <Input
                          className={"amp-unmask"}
                          type={"date"}
                          size={"sm"}
                          borderRadius={"md"}
                          value={dateFilterEnd}
                          onChange={(e) => handleDateFilterEnd(e.target.value)}
                        />
                        <FormHelperText>To</FormHelperText>
                      </FormControl>
                    </HStack>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuOptionGroup
                    value={selectedStatuses}
                    title="Status"
                    type="checkbox"
                    onChange={(values) => handleStatusSelect(values)}
                  >
                    <MenuItemOption value="analyzing">Analyzing</MenuItemOption>
                    <MenuItemOption value="analyzed">Analyzed</MenuItemOption>
                    <MenuItemOption value="reviewing">Reviewing</MenuItemOption>
                    <MenuItemOption value="completed">Completed</MenuItemOption>
                    <MenuItemOption value="error">Error</MenuItemOption>
                    <MenuItemOption value="archived">Archived</MenuItemOption>
                  </MenuOptionGroup>
                  {languages.length > 0 && (
                    <>
                      <MenuDivider />
                      <MenuOptionGroup title={"Language"} />
                      <MenuItemOption
                        type="checkbox"
                        key={"all"}
                        value={"all"}
                        isChecked={selectedLanguages.length === 0}
                        onClick={() => handleLanguageSelect([])}
                      >
                        All
                      </MenuItemOption>
                      <MenuOptionGroup
                        value={selectedLanguages}
                        type="checkbox"
                        onChange={(values) => handleLanguageSelect(values)}
                      >
                        {languages.map((language) => {
                          return (
                            <MenuItemOption key={language.code} value={language.code}>
                              {language.name}
                            </MenuItemOption>
                          );
                        })}
                      </MenuOptionGroup>
                    </>
                  )}
                  <MenuDivider />
                  <MenuGroup>
                    <Button
                      size={"sm"}
                      colorScheme={"gray"}
                      variant={"ghost"}
                      onClick={resetSearchFilters}
                      mx={3}
                      fontWeight={"normal"}
                    >
                      Reset filters
                    </Button>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Box>

            <Box>
              <Menu closeOnSelect={false}>
                <MenuButton as={IconButton} icon={<SortThree />} size={"sm"} />
                <MenuList minWidth="260px">
                  <MenuOptionGroup
                    defaultValue={itemSortDirection}
                    value={itemSortDirection}
                    title="Order"
                    type="radio"
                    onChange={handleItemSortDirection}
                  >
                    <MenuItemOption value="asc">Ascending</MenuItemOption>
                    <MenuItemOption value="desc">Descending</MenuItemOption>
                  </MenuOptionGroup>
                  <MenuDivider />
                  <MenuOptionGroup
                    defaultValue={itemSortOption}
                    value={itemSortOption}
                    title="Sort by"
                    type="radio"
                    onChange={handleItemSortOption}
                  >
                    <MenuItemOption value="published">Date published</MenuItemOption>
                    <MenuItemOption value="status">Status</MenuItemOption>
                    <MenuItemOption value="channel">Channel</MenuItemOption>
                    <MenuItemOption value="user">User Moments</MenuItemOption>
                    <MenuItemOption value="duration">Duration</MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </HStack>
      </HStack>
      {elements.length > 0 ? (
        <SimpleGrid
          columns={{ md: 1, lg: 2, xl: 3, "2xl": 4, "3xl": 5, "4xl": 6 }}
          spacing={4}
          px={4}
          pb={4}
          mt={"0.5 !important"}
          zIndex={2}
          overflow={"auto"}
          justifyContent={"space-between"}
          h={"100%"}
          className="campaign-searches"
          flex={1}
          alignContent={"flex-start"}
          w={"100%"}
        >
          {elements.map((search: RivrSearch) => (
            <CampaignSearchItem key={search.id} search={search} fetchSearches={fetchSearches} />
          ))}
        </SimpleGrid>
      ) : (
        <Text fontSize="md" color="gray.300" textAlign="center" w="100%" px={8} py={32}>
          {`${selectedChannelsSearchCount} video${
            selectedChannelsSearchCount === 1 ? " is" : "s are"
          } hidden by your filter settings`}
        </Text>
      )}
      <HStack
        py={4}
        bg={"gray.800"}
        mt={"0 !important"}
        pos={"sticky"}
        bottom={0}
        w={"100%"}
        borderTopWidth={1}
        zIndex={1}
      >
        <HStack w={"100%"} justifyContent={"center"}>
          <HStack w={"fit-content"}>
            <Text fontSize={"sm"} color={"gray.400"}>
              Showing
            </Text>
            <Select
              size={"sm"}
              borderRadius={"md"}
              color={"gray.400"}
              value={pageSize}
              minW={"max-content"}
              variant={"outline"}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option key={5} value={5}>
                5
              </option>
              <option key={10} value={10}>
                10
              </option>
              <option key={15} value={15}>
                15
              </option>
              <option key={20} value={20}>
                20
              </option>
              <option key={30} value={30}>
                30
              </option>
            </Select>
          </HStack>
          <IconButton
            title={"First page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"First page"}
            icon={<DoubleLeft />}
            onClick={() => setPage(1)}
            disabled={page === 1}
          />
          <IconButton
            title={"Previous page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"Previous page"}
            icon={<Left />}
            onClick={previousPage}
            disabled={!hasPreviousPage}
          />
          <HStack px={2}>
            <Text fontSize={"sm"} color={"gray.400"}>
              Page
            </Text>
            <Input
              className={"amp-unmask"}
              textAlign={"right"}
              textColor={"gray.400"}
              value={page}
              size={"sm"}
              maxW={14}
              borderRadius={"md"}
              type={"number"}
              onChange={(e) => {
                setPage(Number(e.target.value));
              }}
            />
            <Text fontSize={"sm"} color={"gray.400"}>{` of ${totalPages}`}</Text>
          </HStack>
          <IconButton
            title={"Next page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"Next page"}
            icon={<Right />}
            onClick={nextPage}
            disabled={!hasNextPage}
          />
          <IconButton
            title={"Last page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"Last page"}
            icon={<DoubleRight />}
            onClick={() => setPage(totalPages)}
            disabled={page === totalPages}
          />
        </HStack>
      </HStack>
    </VStack>
  ) : (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        {selectedCampaign === "" ? "Select a campaign" : "No videos in the selected campaign"}
      </Text>
    </HStack>
  );
};
