import {
  Flex,
  HStack,
  VStack,
  Text,
  Checkbox,
  Center,
  Spinner,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { AddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { MomentItem } from "../MomentItem/MomentItem";
import { FilterPopover, MomentFilter } from "../FilterPopover/FilterPopover";
import { DeleteConfirmation } from "../DeleteConfirmation/DeleteConfirmation";
import ApplyActionSet from "../ApplyActionSet/ApplyActionSet";
import { MoreOne } from "@icon-park/react";
import { Moment } from "../../../models/moment";
import { Action, defaultAction } from "../../../models/action";
import { VerticalFormatModal } from "../../VerticalFormatModal/VerticalFormatModal";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { DownloadsContext } from "../../../context/DownloadsContext";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  moments: Moment[];
  focusedCallback: (id: string | null) => void;
  focusedItem: string | null;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  searchId: string;
  filters: MomentFilter[];
  addFilter: (filter: MomentFilter) => void;
  removeFilter: (filter: MomentFilter) => void;
  videoInfo: any;
  pauseMedia: () => void;
};

export const UserMoments = (props: Props) => {
  const {
    moments,
    focusedCallback,
    focusedItem,
    deleteMoments,
    updateMoment,
    searchId,
    filters,
    addFilter,
    removeFilter,
    videoInfo,
    pauseMedia,
  } = props;
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const downloadDisabled = false;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const [actionSetMoments, setActionSetMoments] = useState<string[] | null>(null);
  const momentsLoading = videoInfo === null;
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  const { downloaderRequest, verticalFormatClips, setVerticalFormatClips } =
    useContext(DownloadsContext);

  const selectActions = (actions: Action[]) => {
    if (actionSetMoments !== null) createDownloaderRequest(actionSetMoments, actions);
  };

  const verticalFormat = (action: Action) => {
    createDownloaderRequest(
      verticalFormatClips.map((v) => v.momentId),
      [action]
    );
  };

  const createDownloaderRequest = (
    momentIds: string[],
    actions?: Action[],
    forVerticalFormat?: boolean
  ) => {
    const downloadMoments: Moment[] = [];
    momentIds.forEach((id) => {
      const moment = moments.find((item) => item.id === id);
      if (moment) downloadMoments.push(moment);
    });
    downloaderRequest(downloadMoments, searchId, actions ?? [defaultAction], forVerticalFormat);
  };

  useEffect(() => {
    setCheckedItems(checkedItems.filter((id) => moments.some((moment) => moment.id === id)));
  }, [moments]);

  const orderedMoments = [...moments].sort((a, b) => a.start_time - b.start_time);

  const checkCallback = (id: string) => {
    if (checkedItems.includes(id)) setCheckedItems(checkedItems.filter((item) => item !== id));
    else setCheckedItems([...checkedItems, id]);
  };

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setCheckedItems(moments.map((result) => result.id));
    else setCheckedItems([]);
  };

  const deselectMoment = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      focusedCallback(null);
    }
  };

  const addFilterInter = (filter: MomentFilter) => {
    setResetFilter(false);
    addFilter(filter);
  };

  const removeFilterInter = (filter: MomentFilter) => {
    setResetFilter(true);
    removeFilter(filter);
  };

  const verticalReformat = (momentId: string) => {
    createDownloaderRequest([momentId], [defaultAction], true);
    setVerticalFormatClips([{ momentId }]);
    pauseMedia();
  };

  const canAccessMoments = videoInfo && videoInfo.status === "stopped";

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Flex
      className={"results-column"}
      flexDirection={"column"}
      w={"100%"}
      h={"100%"}
      onClick={deselectMoment}
      pt={1}
    >
      {filters.length > 0 && (
        <HStack alignItems={"center"} mt={2}>
          <Text
            color={"gray.300"}
            fontSize={"xs"}
            fontWeight={"medium"}
            textTransform={"uppercase"}
          >
            Filters:
          </Text>
          {filters.map((filter, index) => (
            <Badge
              key={index}
              display={"flex"}
              alignItems={"center"}
              height={"fit-content"}
              mt={2}
              width={"fit-content"}
              colorScheme={"green"}
              variant={"subtle"}
              fontWeight={"medium"}
              borderRadius={"sm"}
              whiteSpace={"normal"}
            >
              {filter.mode === "all" ? "All of" : "Any of"}: {filter.tags.join(", ")}
              <IconButton
                aria-label={"button"}
                colorScheme={"gray"}
                variant={"ghost"}
                minW={"fit-content"}
                h={"fit-content"}
                size={"sm"}
                ml={0.5}
                bg={"transparent"}
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                onClick={() => removeFilterInter(filter)}
                icon={<SmallCloseIcon />}
              />
            </Badge>
          ))}
        </HStack>
      )}
      <ApplyActionSet
        isOpen={actionSetMoments !== null}
        onClose={() => setActionSetMoments(null)}
        headerText={"Apply Action Set"}
        descriptionText={
          "Apply an Action Set to perform all of its Actions on the selected Moments."
        }
        selectLabel={"Action Set"}
        listLabel={"Actions to be performed:"}
        selectActions={selectActions}
      />
      {verticalFormatClips.length > 0 && (
        <VerticalFormatModal
          verticalFormatClips={verticalFormatClips}
          onClose={() => setVerticalFormatClips([])}
          onConfirm={verticalFormat}
        />
      )}
      {!canAccessMoments || moments.length === 0 ? (
        <Center flexDirection="column" height="100%" onClick={() => focusedCallback(null)}>
          {!canAccessMoments ? (
            <>
              <HStack>
                <Text fontSize="lg" fontWeight="medium" textAlign="center">
                  Analyzing...
                </Text>
                <Spinner size="sm" />
              </HStack>
              <Text
                mt="2"
                color="gray.300"
                fontSize="md"
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Create Moments by clicking on the Timeline then selecting the{" "}
                <IconButton
                  aria-label="Add Moment Button"
                  colorScheme={"green"}
                  variant="solid"
                  size="xs"
                  icon={<AddIcon />}
                  pointerEvents={"none"}
                />{" "}
                button once analysis has completed.
              </Text>
            </>
          ) : momentsLoading ? (
            <HStack>
              <Text fontSize="lg" fontWeight="medium" textAlign="center">
                Loading...
              </Text>
              <Spinner size="sm" />
            </HStack>
          ) : (
            <>
              <Text fontSize="lg" fontWeight="medium" textAlign={"center"}>
                No Moments
              </Text>
              <Text
                mt="2"
                color="gray.300"
                fontSize="md"
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Create Moments by clicking on the Timeline then selecting the{" "}
                <IconButton
                  aria-label="Add Moment Button"
                  colorScheme={"green"}
                  variant="solid"
                  size="xs"
                  icon={<AddIcon />}
                  pointerEvents={"none"}
                />{" "}
                button.
              </Text>
            </>
          )}
        </Center>
      ) : (
        <>
          <HStack flex="0 0 100%" justifyContent="space-between" maxHeight="32px" mt="3">
            <Checkbox
              className="inp-select-all-results"
              w="auto"
              ml="4"
              colorScheme={"teal"}
              size="md"
              color="gray.400"
              onChange={selectAll}
              isChecked={checkedItems.length > 0 && checkedItems.length === moments.length}
            >
              <Text color="gray.400" fontSize="xs" fontWeight="medium" textTransform="uppercase">
                Select all
              </Text>
            </Checkbox>
            <Flex alignItems="center">
              {checkedItems.length > 0 && (
                <Text fontSize="xs" mr="3" fontWeight="medium" color="gray.400">
                  {checkedItems.length} Selected
                </Text>
              )}
              <Box pr={2}>
                <FilterPopover addFilter={addFilterInter} resetFilter={resetFilter} />
              </Box>
              <Menu isLazy>
                <MenuButton
                  isDisabled={checkedItems.length === 0}
                  as={IconButton}
                  className="btn-select-all-results-menu"
                  colorScheme="gray"
                  variant="ghost"
                  size="sm"
                  bg="gray.700"
                  _hover={{ bg: "gray.600" }}
                  _active={{ bg: "gray.600" }}
                  icon={<MoreOne color="currentColor" size={24} />}
                />
                <MenuList>
                  {downloadDisabled ? (
                    <MenuItem
                      opacity="0.3"
                      cursor="not-allowed"
                      className="po-btn-select-all-download"
                    >
                      Download
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        createDownloaderRequest(checkedItems);
                        handleAmplitudeClick("Download Bulk Moment");
                      }}
                      className="po-btn-select-all-download"
                    >
                      Download
                    </MenuItem>
                  )}
                  {!demoUser && (
                    <MenuItem
                      onClick={() => setShowDeleteConfirmation(true)}
                      className="po-btn-select-all-delete-moment"
                    >
                      Delete
                    </MenuItem>
                  )}
                </MenuList>
                <DeleteConfirmation
                  confirm={() => deleteMoments(checkedItems)}
                  isOpen={showDeleteConfirmation}
                  onClose={() => setShowDeleteConfirmation(false)}
                  headerText={"Delete Moments"}
                  bodyText={"Are you sure you want to delete all selected Moments?"}
                />
              </Menu>
            </Flex>
          </HStack>
          <VStack
            className="result-list"
            mt="4"
            overflowY="auto"
            overflowX="hidden"
            flex="1"
            onClick={deselectMoment}
            gap={0}
          >
            {orderedMoments.map((moment) => (
              <MomentItem
                key={moment.id}
                inputs={{
                  kind: "UserMoment",
                  moment,
                  downloadDisabled,
                  downloadCallback: () => createDownloaderRequest([moment.id]),
                  deleteMoments,
                  updateMoment,
                  applyActionSet: () => setActionSetMoments([moment.id]),
                  verticalReformat: () => verticalReformat(moment.id),
                }}
                checkCallback={checkCallback}
                focusedCallback={focusedCallback}
                isChecked={checkedItems.some((item) => item === moment.id)}
                isFocused={focusedItem === moment.id}
              />
            ))}
          </VStack>
        </>
      )}
    </Flex>
  );
};
