import { AddIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import { CampaignSearchBar } from "./CampaignSearchBar";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Close, SortThree } from "@icon-park/react";
import { useMemo, useState } from "react";
import "./Campaigns.css";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams, updateURLParams } from "../../models/navigation";
import { RivrChannel } from "../../models/rivrChannel";
import BillingCta from "../BillingCta/BillingCta";
import { OrganizationMembership } from "../../models/rivrOrganization";

interface Props {
  channels: RivrChannel[];
  selectedMembership: OrganizationMembership;
  fetchSearches: any;
}

export const CampaignChannels = ({ channels, selectedMembership, fetchSearches }: Props) => {
  const { getFlag } = useKindeAuth();
  const { isOpen: isBillingOpen, onClose: onBillingClose, onOpen: onBillingOpen } = useDisclosure();

  const demoUser = getFlag("demo-user").value as boolean;
  const hasAccess = selectedMembership.organization.subscriptionStatus === "active";

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const selectedChannels = searchParams.getAll(CampaignURLParams.SelectedChannels) || [];
  const channelFilterText = searchParams.get(CampaignURLParams.ChannelFilterText) || "";
  const channelSortOption = searchParams.get(CampaignURLParams.ChannelSortOption) || "";
  const channelSortDirection = searchParams.get(CampaignURLParams.ChannelSortDirection) || "";

  const handleChannelSelect = (channel: string) => {
    if (selectedChannels.includes(channel)) {
      selectedChannels.splice(selectedChannels.indexOf(channel), 1);
    } else {
      selectedChannels.push(channel);
    }

    const newParams: Array<[string, string[]]> = [
      [
        CampaignURLParams.SelectedChannels,
        selectedChannels.sort((a, b) => {
          return a === "N/A"
            ? 1
            : b === "N/A"
            ? -1
            : a.toLowerCase().localeCompare(b.toLowerCase());
        }),
      ],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleChannelFilterText = (text: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.ChannelFilterText, text]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleChannelSortOption = (option: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.ChannelSortOption, option],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleChannelSortDirection = (direction: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.ChannelSortDirection, direction],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const resetChannelSelection = () => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.SelectedChannels, []],
      [CampaignURLParams.ChannelFilterText, ""],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const [isSearchBarModalOpen, setIsSearchBarModalOpen] = useState(false);

  const sortedChannels = useMemo(() => {
    const filteredChannels = channels.filter((c) =>
      c.name.toLowerCase().includes(channelFilterText.toLowerCase())
    );

    return filteredChannels
      .map((c) => ({
        channel: c.name,
        language: c.languages[0] || "",
        searchCount: c.searchCount,
      }))
      .sort((a, b) => {
        if (a.channel === "N/A") {
          return 1;
        }
        if (b.channel === "N/A") {
          return -1;
        }
        if (channelSortOption === "channel") {
          return channelSortDirection === "asc"
            ? a.channel.localeCompare(b.channel)
            : b.channel.localeCompare(a.channel);
        } else if (channelSortOption === "language") {
          if (a.language === b.language) {
            return a.channel.localeCompare(b.channel);
          }
          return channelSortDirection === "asc"
            ? a.language.code.localeCompare(b.language.code)
            : b.language.code.localeCompare(a.language.code);
        } else if (channelSortOption === "searchCount") {
          if (a.searchCount === b.searchCount) {
            return a.channel.localeCompare(b.channel);
          }
          return channelSortDirection === "asc"
            ? a.searchCount - b.searchCount
            : b.searchCount - a.searchCount;
        }
        return 0;
      });
  }, [channels, channelSortOption, channelSortDirection, channelFilterText]);

  const handleAddVideo = () => {
    if (hasAccess) setIsSearchBarModalOpen(true);
    else onBillingOpen();
  };

  return (
    <>
      <Modal
        isOpen={isSearchBarModalOpen}
        onClose={() => setIsSearchBarModalOpen(false)}
        closeOnOverlayClick={false}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add videos to campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Add Twitch URLs in the field below. They must be comma separated.</Text>
            <CampaignSearchBar
              selectedCampaignId={selectedCampaign}
              fetchSearches={fetchSearches}
              onClose={() => setIsSearchBarModalOpen(false)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <BillingCta membership={selectedMembership} isOpen={isBillingOpen} onClose={onBillingClose} />
      <VStack
        w={"300px"}
        minW={"300px"}
        bg={"gray.900"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        p={4}
        borderRightWidth={1}
        className={"campaign-channels"}
        h={"100%"}
      >
        {selectedCampaign !== "" && (
          <>
            <Button
              leftIcon={<AddIcon />}
              size={"sm"}
              minH={8}
              colorScheme={"green"}
              onClick={handleAddVideo}
              w={"full"}
              disabled={demoUser}
            >
              Add videos
            </Button>
            <Divider pt={2} />
          </>
        )}
        {channels.length > 0 ? (
          <>
            <HStack justifyContent={"space-between"} mb={2} pt={2} w={"100%"}>
              <HStack>
                <Text fontSize={"sm"}>Channels in campaign</Text>
                <Text
                  fontSize={"sm"}
                  bg={"blackAlpha.500"}
                  w={"fit-content"}
                  px={1}
                  py={0.5}
                  minW={"1.4em"}
                  borderRadius={"full"}
                  textAlign={"center"}
                >
                  {channels.length}
                </Text>
              </HStack>
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={IconButton}
                    icon={<SortThree />}
                    size={"sm"}
                    isDisabled={channels.length < 0}
                  />
                  <MenuList minWidth={"240px"} zIndex={3}>
                    <MenuOptionGroup
                      defaultValue={channelSortDirection}
                      title={"Order"}
                      type={"radio"}
                      onChange={handleChannelSortDirection}
                    >
                      <MenuItemOption value={"asc"}>Ascending</MenuItemOption>
                      <MenuItemOption value={"desc"}>Descending</MenuItemOption>
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuOptionGroup
                      defaultValue={channelSortOption}
                      title={"Sort by"}
                      type={"radio"}
                      onChange={handleChannelSortOption}
                    >
                      <MenuItemOption value={"channel"}>Channel</MenuItemOption>
                      <MenuItemOption value={"language"}>Language</MenuItemOption>
                      <MenuItemOption value={"searchCount"}>Number of videos</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
            </HStack>
            <InputGroup size={"sm"} pb={2}>
              <Input
                className={"amp-unmask"}
                type={"text"}
                placeholder={"Filter by channel"}
                value={channelFilterText}
                onChange={(e) => handleChannelFilterText(e.target.value)}
                size={"sm"}
                borderRadius={"md"}
              />
              {channelFilterText && (
                <InputRightElement>
                  <IconButton
                    aria-label={"Clear text search"}
                    icon={<Close />}
                    size={"xs"}
                    variant={"ghost"}
                    borderRadius={"full"}
                    onClick={() => handleChannelFilterText("")}
                  />
                </InputRightElement>
              )}
            </InputGroup>
            <Button
              size={"sm"}
              w={"full"}
              isActive={selectedChannels.length === 0 ? true : false}
              colorScheme={selectedChannels.length === 0 ? "blue" : "gray"}
              variant={selectedChannels.length === 0 ? "ghost" : "solid"}
              onClick={resetChannelSelection}
              minH={8}
              fontWeight={"normal"}
            >
              All channels
            </Button>
            {sortedChannels.length > 0 ? (
              <VStack w="100%" h="100%" overflowY={"auto"} className="campaign-channels">
                {sortedChannels.map((channel, index) => (
                  <Button
                    key={index}
                    size={"sm"}
                    w={"full"}
                    minH={8}
                    justifyContent={"space-between"}
                    onClick={() => handleChannelSelect(channel.channel)}
                    outline={selectedChannels.includes(channel.channel) ? "2px solid" : "none"}
                    outlineColor={
                      selectedChannels.includes(channel.channel) ? "whiteAlpha.300" : "none"
                    }
                    outlineOffset="-2px"
                    bg={
                      selectedChannels.length > 0 && selectedChannels.includes(channel.channel)
                        ? "whiteAlpha.300"
                        : undefined
                    }
                  >
                    <Text noOfLines={1} mr={2} title={channel.channel} fontWeight={"normal"}>
                      {channel.channel}
                    </Text>
                    <HStack spacing={2}>
                      <Text
                        fontSize={"sm"}
                        fontWeight={"medium"}
                        bg={"blackAlpha.500"}
                        w={"fit-content"}
                        px={1.5}
                        py={0.5}
                        borderRadius={"full"}
                        textAlign={"center"}
                        minW={"1.4em"}
                        title={`${channel.searchCount} videos`}
                      >
                        {channel.searchCount}
                      </Text>
                      {channel.channel !== "N/A" && (
                        <Badge
                          key={channel.language.code}
                          fontSize="xs"
                          py={0.5}
                          px={1}
                          minW={"2em"}
                          letterSpacing={1}
                        >
                          {channel.language.code}
                        </Badge>
                      )}
                    </HStack>
                  </Button>
                ))}
              </VStack>
            ) : (
              <Text
                color={"gray.300"}
                alignSelf={"center"}
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
                pt={3}
              >
                No channels matching your filter settings
              </Text>
            )}
          </>
        ) : (
          <VStack w={"100%"} h={"90%"} py={8} mx={"auto"} justifyContent={"center"}>
            <Text
              color={"gray.300"}
              alignSelf={"center"}
              textAlign={"center"}
              sx={{ textWrap: "balance" }}
            >
              {selectedCampaign === ""
                ? "Select a campaign"
                : "No channels in the selected campaign"}
            </Text>
          </VStack>
        )}
      </VStack>
    </>
  );
};
