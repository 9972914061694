import { Tabs, TabList, Tab, TabPanels, TabPanel, Badge } from "@chakra-ui/react";
import { MomentFilter } from "./FilterPopover/FilterPopover";
import "./MomentPanel.css";
import { Moment, RecommendedMoment } from "../../models/moment";
import { UserMoments } from "./UserMoments/UserMoments";
import { RecommendedMoments } from "./RecommendedMoments/RecommendedMoments";
import { SelectedMoment } from "../../models/selectedMoment";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  moments: Moment[];
  recommendedMoments: RecommendedMoment[];
  toggleMoment: (id: string) => void;
  totalMoments: number;
  focusedCallback: (selectedMoment: SelectedMoment | null) => void;
  focusLastMoment: () => void;
  focusedItem: string | null;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  searchId: string;
  filters: MomentFilter[];
  addFilter: (filter: MomentFilter) => void;
  removeFilter: (filter: MomentFilter) => void;
  videoInfo: any;
  recommendedMomentsLoading: boolean;
  acceptMoments: (recommended: RecommendedMoment[]) => void;
  momentTabIndex: number;
  setMomentTabIndex: (index: number) => void;
  pauseMedia: () => void;
  recommendedAmount: number;
  setRecommendedAmount: (amount: number) => void;
  minRecommendedAmount: number;
  maxRecommendedAmount: number;
};

export const MomentPanel = (props: Props) => {
  const {
    moments,
    recommendedMoments,
    toggleMoment,
    focusedCallback,
    focusLastMoment,
    focusedItem,
    deleteMoments,
    updateMoment,
    searchId,
    filters,
    addFilter,
    removeFilter,
    videoInfo,
    recommendedMomentsLoading,
    acceptMoments,
    momentTabIndex,
    setMomentTabIndex,
    pauseMedia,
    recommendedAmount,
    setRecommendedAmount,
    minRecommendedAmount,
    maxRecommendedAmount,
  } = props;

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Tabs
      index={momentTabIndex}
      onChange={setMomentTabIndex}
      width="100%"
      display="flex"
      flexDirection="column"
      isFitted={true}
    >
      <TabList flexShrink="0">
        <Tab
          fontWeight={"medium"}
          _hover={{ bg: "whiteAlpha.200" }}
          _selected={{ bg: "whiteAlpha.200", color: "blue.300", borderBottomColor: "blue.300" }}
          borderTopRadius={"md"}
          onClick={() => handleAmplitudeClick("My Moments Tab Clicked")}
        >
          My Moments
        </Tab>
        <Tab
          fontWeight={"medium"}
          _hover={{ bg: "whiteAlpha.200" }}
          _selected={{ bg: "whiteAlpha.200", color: "blue.300", borderBottomColor: "blue.300" }}
          borderTopRadius={"md"}
          onClick={() => handleAmplitudeClick("Recommended Tab Clicked")}
        >
          Recommended
          <Badge ml={1.5} colorScheme={"blue"} size={"xs"}>
            Beta
          </Badge>
        </Tab>
      </TabList>
      <TabPanels flexGrow="1" minH={0}>
        <TabPanel padding="0" height="100%">
          <UserMoments
            moments={moments}
            focusedCallback={(id) => focusedCallback(id === null ? null : { kind: "user", id })}
            focusedItem={focusedItem}
            deleteMoments={deleteMoments}
            updateMoment={updateMoment}
            searchId={searchId}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            videoInfo={videoInfo}
            pauseMedia={pauseMedia}
          />
        </TabPanel>
        <TabPanel padding="0" height="100%">
          <RecommendedMoments
            moments={recommendedMoments}
            focusedCallback={(id) =>
              focusedCallback(id === null ? null : { kind: "recommended", id })
            }
            focusLastMoment={focusLastMoment}
            focusedItem={focusedItem}
            momentsLoading={recommendedMomentsLoading}
            acceptMoments={acceptMoments}
            toggleMoment={toggleMoment}
            videoInfo={videoInfo}
            recommendedAmount={recommendedAmount}
            setRecommendedAmount={setRecommendedAmount}
            minRecommendedAmount={minRecommendedAmount}
            maxRecommendedAmount={maxRecommendedAmount}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
