import { useMemo, useRef, useState } from "react";
import {
  Box,
  VStack,
  Text,
  HStack,
  Button,
  IconButton,
  MenuButton,
  MenuOptionGroup,
  Menu,
  MenuList,
  MenuDivider,
  Divider,
  Input,
  MenuItemOption,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Select,
} from "@chakra-ui/react";
import {
  DoubleLeft,
  DoubleRight,
  Filter,
  Left,
  Plus,
  Right,
  Search,
  SpeakerOne,
} from "@icon-park/react";
import CampaignManager from "../Manage/CampaignManager";
import "../Campaigns.css";
import { RivrCampaign } from "../../../models/rivrCampaign";
import { CampaignItem } from "./CampaignItem";
import { usePagination } from "../../../hooks/usePagination";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

type CampaignStatus = "active" | "paused" | "archived" | "completed";

type Props = {
  campaigns: RivrCampaign[];
  orgId: string | undefined;
  fetchCampaigns: any;
  handleCampaignSelect: any;
};

const CampaignManagement = (props: Props) => {
  const { campaigns, orgId, fetchCampaigns, handleCampaignSelect } = props;
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  const {
    isOpen: isCampaignCreationModalOpen,
    onOpen: openCampaignCreationModal,
    onClose: closeCampaignCreationModal,
  } = useDisclosure();
  const {
    isOpen: isCampaignCreationAlertOpen,
    onOpen: openCampaignCreationAlert,
    onClose: closeCampaignCreationAlert,
  } = useDisclosure();
  const cancelCloseCampaignCreationRef = useRef(null);

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [selectedStatuses, setSelectedStatuses] = useState<CampaignStatus[]>([
    "active",
    "paused",
    "completed",
  ]);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);

  const handleSortOrderChange = (value: "asc" | "desc") => setSortOrder(value);
  const handleStatusChange = (values: CampaignStatus[]) => setSelectedStatuses(values);
  let debounceTimer: any;
  const handleSearchTextChange = (value: string) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setSearchText(value.toLowerCase());
    }, 250);
  };

  const sortCampaignData = useMemo(() => {
    return (a: RivrCampaign, b: RivrCampaign) => {
      if (a.status === null && b.status !== null) {
        return 1;
      } else if (a.status !== null && b.status === null) {
        return -1;
      }

      if (a.archivedAt !== null && b.archivedAt === null) {
        return 1;
      } else if (a.archivedAt === null && b.archivedAt !== null) {
        return -1;
      }

      const statusPriority: Record<CampaignStatus, number> = {
        active: 1,
        paused: 2,
        completed: 3,
        archived: 4,
      };

      return sortOrder === "asc"
        ? statusPriority[a.status] - statusPriority[b.status]
        : statusPriority[b.status] - statusPriority[a.status];
    };
  }, [sortOrder]);

  const filterCampaignData = useMemo(() => {
    return (item: RivrCampaign) => {
      if (item.archivedAt !== null && selectedStatuses.includes("archived") === false) return false;
      if (item.status !== null && selectedStatuses.includes(item.status) === false) return false;
      if (
        searchText &&
        item.title.toLowerCase().includes(searchText) === false &&
        (item.description === null || item.description.toLowerCase().includes(searchText) === false)
      ) {
        return false;
      }

      return true;
    };
  }, [selectedStatuses, searchText]);

  const {
    elements,
    page,
    totalPages,
    setPage,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
  } = usePagination<RivrCampaign>(campaigns, sortCampaignData, filterCampaignData, pageSize);

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={isCampaignCreationAlertOpen}
        leastDestructiveRef={cancelCloseCampaignCreationRef}
        onClose={closeCampaignCreationAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Cancel campaign creation</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to cancel campaign creation? Your changes will not be saved.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant={"ghost"}
                ref={cancelCloseCampaignCreationRef}
                onClick={closeCampaignCreationAlert}
              >
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  closeCampaignCreationModal();
                  closeCampaignCreationAlert();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {orgId && (
        <Modal
          isOpen={isCampaignCreationModalOpen}
          onClose={openCampaignCreationAlert}
          onEsc={openCampaignCreationAlert}
          onOverlayClick={openCampaignCreationAlert}
          size={"lg"}
          isCentered
        >
          <ModalOverlay />
          <ModalContent className={"campaign-create"}>
            <CampaignManager
              campaign={null}
              orgId={orgId}
              fetchCampaigns={fetchCampaigns}
              closeModal={closeCampaignCreationModal}
              openCampaignManagerAlert={openCampaignCreationAlert}
            />
          </ModalContent>
        </Modal>
      )}
      <VStack spacing={0} w={"100%"} maxW={"1600px"} h={"100%"} mx={"auto"} px={6}>
        <Box w={"100%"} pb={4} pos={"sticky"} top={0} zIndex={3}>
          <HStack pt={4} w={"100%"} justifyContent={"space-between"} spacing={4} align={"flex-end"}>
            <VStack w={"100%"} align={"flex-start"} spacing={0}>
              <Text fontWeight={"semibold"} fontSize={"2xl"} color={"gray.50"}>
                Campaigns
              </Text>
              <Text color={"gray.300"}>Create and manage your campaigns</Text>
            </VStack>
            {!demoUser && (
              <Button
                leftIcon={<Plus />}
                colorScheme={"green"}
                minW={"max-content"}
                onClick={openCampaignCreationModal}
                disabled={orgId === undefined}
              >
                Create campaign
              </Button>
            )}
          </HStack>
          <Divider mt={4} />

          <HStack mt={4} w={"100%"} justifyContent={"space-between"}>
            <InputGroup size="sm">
              <InputLeftElement>
                <Search />
              </InputLeftElement>
              <Input
                className={"amp-unmask"}
                placeholder="Filter campaigns by title or description"
                onChange={(e) => handleSearchTextChange(e.target.value)}
                maxW={"24rem"}
                borderRadius={"md"}
              />
            </InputGroup>
            <Box>
              <Menu closeOnSelect={false} placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  colorScheme="gray"
                  icon={<Filter theme="filled" />}
                  size={"sm"}
                />
                <MenuList minWidth="240px">
                  <MenuOptionGroup
                    defaultValue={sortOrder}
                    title="Order"
                    type="radio"
                    onChange={(value) => handleSortOrderChange(value as "asc" | "desc")}
                  >
                    <MenuItemOption value="asc">Ascending</MenuItemOption>
                    <MenuItemOption value="desc">Descending</MenuItemOption>
                  </MenuOptionGroup>
                  <MenuDivider />
                  <MenuOptionGroup
                    defaultValue={selectedStatuses}
                    title="Status"
                    type="checkbox"
                    onChange={(values) => handleStatusChange(values as CampaignStatus[])}
                  >
                    <MenuItemOption value="active">Active</MenuItemOption>
                    <MenuItemOption value="paused">Paused</MenuItemOption>
                    <MenuItemOption value="completed">Completed</MenuItemOption>
                    <MenuItemOption value="archived">Archived</MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </Box>

        <VStack
          w={"100%"}
          mt={"0 !important"}
          zIndex={2}
          overflowY={"auto"}
          h={"100%"}
          className="campaign-manage"
        >
          {elements.length > 0 ? (
            elements.map((campaign) => (
              <CampaignItem
                key={campaign.id}
                campaign={campaign}
                fetchCampaigns={fetchCampaigns}
                handleCampaignSelect={handleCampaignSelect}
              />
            ))
          ) : (
            <>
              <VStack px={4} py={24} spacing={2}>
                {campaigns.length < 1 && (
                  <>
                    <SpeakerOne size={64} />
                    <Text fontSize={"lg"} fontWeight={"medium"} pb={2}>
                      Create your first campaign to get started
                    </Text>
                    {!demoUser && (
                      <Button
                        minW="max-content"
                        colorScheme={"green"}
                        leftIcon={<Plus />}
                        onClick={openCampaignCreationModal}
                        disabled={orgId === undefined}
                      >
                        Create campaign
                      </Button>
                    )}
                  </>
                )}
                {campaigns.length >= 1 && (
                  <>
                    <Text fontSize={"lg"} fontWeight={"medium"}>
                      No campaigns to display
                    </Text>
                    <Text color={"gray.300"}>{`${campaigns.length} campaign${
                      campaigns.length === 1 ? " is" : "s are"
                    } hidden by your filter settings`}</Text>
                  </>
                )}
              </VStack>
            </>
          )}
        </VStack>
        <HStack
          py={4}
          bg={"gray.800"}
          pos={"sticky"}
          bottom={0}
          w={"100%"}
          borderTopWidth={1}
          zIndex={2}
        >
          <HStack w={"100%"} justifyContent={"center"}>
            <HStack w={"fit-content"}>
              <Text fontSize={"sm"} color={"gray.400"}>
                Showing
              </Text>
              <Select
                size={"sm"}
                borderRadius={"md"}
                color={"gray.400"}
                value={pageSize}
                minW={"max-content"}
                variant={"outline"}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                <option key={5} value={5}>
                  5
                </option>
                <option key={10} value={10}>
                  10
                </option>
                <option key={15} value={15}>
                  15
                </option>
                <option key={20} value={20}>
                  20
                </option>
                <option key={30} value={30}>
                  30
                </option>
              </Select>
            </HStack>
            <IconButton
              title={"First page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"First page"}
              icon={<DoubleLeft />}
              onClick={() => setPage(1)}
              disabled={page === 1}
            />
            <IconButton
              title={"Previous page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"Previous page"}
              icon={<Left />}
              onClick={previousPage}
              disabled={!hasPreviousPage}
            />
            <HStack px={2}>
              <Text fontSize={"sm"} color={"gray.400"}>
                Page
              </Text>
              <Input
                className={"amp-unmask"}
                textAlign={"right"}
                textColor={"gray.400"}
                value={page}
                size={"sm"}
                maxW={14}
                borderRadius={"md"}
                type={"number"}
                onChange={(e) => {
                  setPage(Number(e.target.value));
                }}
              />
              <Text fontSize={"sm"} color={"gray.400"}>{` of ${totalPages}`}</Text>
            </HStack>
            <IconButton
              title={"Next page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"Next page"}
              icon={<Right />}
              onClick={nextPage}
              disabled={!hasNextPage}
            />
            <IconButton
              title={"Last page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"Last page"}
              icon={<DoubleRight />}
              onClick={() => setPage(totalPages)}
              disabled={page === totalPages}
            />
          </HStack>
        </HStack>
      </VStack>
    </>
  );
};

export default CampaignManagement;
