import { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import { PlayerProps } from "../PlayerProps";
import { Box } from "@chakra-ui/react";

export const RivrPlayer = (props: PlayerProps) => {
  const { videoInfo, seekTime, pausePlayer, updatePlayTime } = props;
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);

  useEffect(() => {
    if (!videoInfo?.video_id || !videoRef.current) return;
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const bucketUrl = process.env.REACT_APP_MEDIA_BUCKET;
      const originFolder = videoInfo.origin_type.toLowerCase();
      const videoId = videoInfo.video_id;

      const options = {
        autoplay: true,
        controls: true,
        responsive: true,
        fill: true,
        sources: [
          {
            src: `${bucketUrl}/${originFolder}/${videoId}/manifest.m3u8`,
            type: "application/x-mpegURL",
          },
        ],
        controlBar: {
          // We have audio only tracks but the player doesn't need to select it.
          audioTrackButton: false,

          pictureInPictureToggle: false,
        },
        html5: {
          vhs: {
            // As our player is relatively small and the number of resolutions we support is low,
            // allow downscaling.
            limitRenditionByPlayerDimensions: false,
          },
        },
      };

      playerRef.current = videojs(videoElement, options, function () {
        setIsPlayerReady(true);

        // Workaround for getting stuck on audio-only stream
        // https://github.com/videojs/http-streaming/issues/175
        // https://github.com/videojs/video.js/issues/5583
        const vhs = (this.tech() as any).vhs; // http-streaming types not available.
        if (vhs) {
          vhs.selectPlaylist = function () {
            return this.playlists.media();
          };
        }
      });
    }
  }, [videoInfo, videoRef]);

  useEffect(() => {
    if (
      isPlayerReady &&
      seekTime &&
      seekTime.seconds >= 0 &&
      seekTime.seconds < (playerRef.current?.duration() as number)
    )
      playerRef.current?.currentTime(seekTime.seconds);
  }, [seekTime, isPlayerReady]);

  useEffect(() => {
    if (isPlayerReady && pausePlayer.pause) playerRef.current?.pause();
  }, [pausePlayer, isPlayerReady]);

  useEffect(() => {
    if (!isPlayerReady) return;

    const interval = setInterval(() => {
      updatePlayTime(playerRef.current?.currentTime() as number);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isPlayerReady]);

  useEffect(() => {
    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return <Box data-vjs-player h={"100%"} ref={videoRef} />;
};
