import {
  HStack,
  Text,
  Box,
  Skeleton,
  Center,
  Spinner,
  Flex,
  Image,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { RivrPlayer } from "./RivrPlayer/RivrPlayer";
import { TwitchRivrPlayer } from "./TwitchRivrPlayer/TwitchRivrPlayer";
import { YouTubeRivrPlayer } from "./YouTubeRivrPlayer/YouTubeRivrPlayer";
import { originTwitch, originYouTube } from "../../models/urls";
import TwitchLogo from "../../assets/twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";
import { ExternalLinkIcon } from "@chakra-ui/icons";

type Props = {
  videoInfo: any;
  seekTime: { seconds: number } | null;
  pausePlayer: { pause: boolean };
  updatePlayTime: (seconds: number) => void;
};

export const MediaPlayer = (props: Props) => {
  const { videoInfo, seekTime, pausePlayer, updatePlayTime } = props;
  const { getFlag } = useKindeAuth();
  const useCustomPlayer = getFlag("custom-player", false).value as boolean;

  const Player = useCustomPlayer
    ? RivrPlayer
    : videoInfo
    ? videoInfo.origin_type === originTwitch
      ? TwitchRivrPlayer
      : videoInfo.origin_type === originYouTube
      ? YouTubeRivrPlayer
      : null
    : null;

  return (
    <>
      <HStack minH={9} maxH={9} w={"100%"} justifyContent={"space-between"}>
        {videoInfo ? (
          <>
            <HStack>
              {videoInfo.origin_type === originTwitch ? (
                <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="18px" mr={1} />
              ) : videoInfo.origin_type === originYouTube ? (
                <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="18px" mr={1} />
              ) : null}
              <Text fontSize="lg" fontWeight="medium" noOfLines={1} title={videoInfo.video_title}>
                {videoInfo.video_title}
              </Text>
            </HStack>
            <Tooltip
              label={
                videoInfo.origin_type === originTwitch
                  ? "View on Twitch"
                  : videoInfo.origin_type === originYouTube
                  ? "View on YouTube"
                  : "View original video"
              }
            >
              <IconButton
                size={"xs"}
                variant={"ghost"}
                as={"a"}
                href={videoInfo.url}
                target={"_blank"}
                aria-label={
                  videoInfo.origin_type === originTwitch
                    ? "View on Twitch"
                    : videoInfo.origin_type === originYouTube
                    ? "View on YouTube"
                    : "View original video"
                }
                icon={<ExternalLinkIcon />}
              />
            </Tooltip>
          </>
        ) : (
          <Skeleton height="20px" w="50%" />
        )}
      </HStack>

      <Box
        h={"100%"}
        backgroundColor="gray.700"
        p={3}
        borderWidth={1}
        borderRadius={"md"}
        className={"player-wrapper"}
      >
        {!videoInfo && (
          <Center flexDirection="column" justifyContent="center" height="100%">
            <Flex alignItems="center">
              <Text mr="2" fontSize="lg" fontWeight="medium">
                Analyzing media...
              </Text>
              <Spinner size="sm" />
            </Flex>
          </Center>
        )}
        {Player && (
          <Player
            seekTime={seekTime}
            pausePlayer={pausePlayer}
            videoInfo={videoInfo}
            updatePlayTime={updatePlayTime}
          />
        )}
      </Box>
    </>
  );
};
