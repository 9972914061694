import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import ImgNotFound from "../../../assets/image-not-found-small.png";
import {
  DeleteFive,
  HistoryQuery,
  Inbox,
  InboxIn,
  InboxOut,
  MoreOne,
  PauseOne,
  PlayOne,
  Sport,
} from "@icon-park/react";
import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import { CampaignStatus } from "../../../models/rivrCampaign";
import { hexToBase64 } from "../../../utils/image";
import { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  SetCampaignArchiveStatusMutation,
  SetCampaignDeleteStatusMutation,
  SetCampaignStatusMutation,
} from "../../../api/campaign";
import Cookies from "js-cookie";
import CampaignManager from "../Manage/CampaignManager";

type Props = {
  campaign: any;
  fetchCampaigns: any;
  handleCampaignSelect: any;
};

export const CampaignItem = (props: Props) => {
  const { campaign, fetchCampaigns, handleCampaignSelect } = props;
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value as boolean;

  interface StatusDetail {
    colorScheme: string;
    icon: JSX.Element;
  }

  const statusDetails: Record<CampaignStatus, StatusDetail> = {
    active: { colorScheme: "green", icon: <Sport size="16" /> },
    paused: { colorScheme: "yellow", icon: <PauseOne size="16" /> },
    archived: { colorScheme: "facebook", icon: <Inbox size="16" /> },
    completed: { colorScheme: "blue", icon: <CheckIcon /> },
  };

  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const isArchived = campaign.archivedAt !== null;

  const {
    isOpen: isCampaignEditModalOpen,
    onOpen: openCampaignEditModal,
    onClose: closeCampaignEditModal,
  } = useDisclosure();
  const {
    isOpen: isCampaignEditAlertOpen,
    onOpen: openCampaignEditAlert,
    onClose: closeCampaignEditAlert,
  } = useDisclosure();
  const cancelCloseCampaignEditRef = useRef(null);
  const {
    isOpen: isCampaignDeleteAlertOpen,
    onOpen: openCampaignDeleteAlert,
    onClose: closeCampaignDeleteAlert,
  } = useDisclosure();
  const cancelCloseCampaignDeleteRef = useRef(null);

  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const [setCampaignStatusAPI] = useMutation(SetCampaignStatusMutation, {
    onCompleted() {
      fetchCampaigns();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [archiveCampaignAPI] = useMutation(SetCampaignArchiveStatusMutation, {
    onCompleted() {
      fetchCampaigns();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [deleteCampaignAPI] = useMutation(SetCampaignDeleteStatusMutation, {
    onCompleted() {
      fetchCampaigns();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const setCampaignStatus = (status: string) => {
    setIsStatusLoading(true);
    setCampaignStatusAPI({
      variables: { id: campaign.id, status: status },
    });
  };

  const archiveCampaign = (archived: boolean) => {
    setIsStatusLoading(true);
    archiveCampaignAPI({
      variables: {
        id: campaign.id,
        date: archived ? new Date(Date.now()) : null,
        status: campaign.status === "active" ? "paused" : campaign.status,
      },
    });
  };

  const deleteCampaign = (deleted: boolean) => {
    setIsStatusLoading(true);
    deleteCampaignAPI({
      variables: { id: campaign.id, date: deleted ? new Date(Date.now()) : null },
    });
  };

  const campaignDescription = (): string => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (today < campaign.startDate) {
      return `${Math.round(
        (campaign.startDate.valueOf() - today.valueOf()) / (1000 * 60 * 60 * 24)
      )} days until campaign begins`;
    }
    if (today < campaign.endDate) {
      return `${Math.round(
        (campaign.endDate.valueOf() - today.valueOf()) / (1000 * 60 * 60 * 24)
      )} days remaining in campaign`;
    }
    if (campaign.description !== "") {
      return campaign.description;
    }
    return "";
  };

  const getBadgeForStatus = () => {
    if (isArchived) {
      return (
        <Badge
          colorScheme={statusDetails["archived" as CampaignStatus].colorScheme}
          fontSize={"sm"}
          borderRadius={"full"}
          py={0.5}
          pl={3}
          pr={0.5}
          letterSpacing={1}
          borderWidth={1}
        >
          <HStack align={"center"}>
            <Text pb={0.5}>{"archived"}</Text>
            <Flex
              bg={"blackAlpha.300"}
              borderRadius={"full"}
              p={1}
              boxSize={7}
              align={"center"}
              justify={"center"}
            >
              {statusDetails["archived" as CampaignStatus].icon}
            </Flex>
          </HStack>
        </Badge>
      );
    }
    if (["active", "completed", "paused"].includes(campaign.status)) {
      return (
        <Badge
          colorScheme={statusDetails[campaign.status as CampaignStatus].colorScheme}
          fontSize={"sm"}
          borderRadius={"full"}
          py={0.5}
          pl={3}
          pr={0.5}
          letterSpacing={1}
          borderWidth={1}
        >
          <HStack align={"center"}>
            <Text pb={0.5}>{campaign.status}</Text>
            <Flex
              bg={"blackAlpha.300"}
              borderRadius={"full"}
              p={1}
              boxSize={7}
              align={"center"}
              justify={"center"}
            >
              {statusDetails[campaign.status as CampaignStatus].icon}
            </Flex>
          </HStack>
        </Badge>
      );
    }
  };

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={isCampaignEditAlertOpen}
        leastDestructiveRef={cancelCloseCampaignEditRef}
        onClose={closeCampaignEditAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Cancel campaign changes</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to cancel campaign management? Your changes will not be saved.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant={"ghost"}
                ref={cancelCloseCampaignEditRef}
                onClick={closeCampaignEditAlert}
              >
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  closeCampaignEditModal();
                  closeCampaignEditAlert();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isCentered
        isOpen={isCampaignDeleteAlertOpen}
        leastDestructiveRef={cancelCloseCampaignDeleteRef}
        onClose={closeCampaignDeleteAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete campaign</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete the campaign? This cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant={"ghost"}
                ref={cancelCloseCampaignDeleteRef}
                onClick={closeCampaignDeleteAlert}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  deleteCampaign(true);
                  closeCampaignDeleteAlert();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isCampaignEditModalOpen}
        onClose={openCampaignEditAlert}
        onEsc={openCampaignEditAlert}
        onOverlayClick={openCampaignEditAlert}
        size={"lg"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent className={"campaign-create"}>
          <CampaignManager
            campaign={campaign}
            orgId={campaign.orgId}
            fetchCampaigns={fetchCampaigns}
            closeModal={closeCampaignEditModal}
            openCampaignManagerAlert={openCampaignEditAlert}
          />
        </ModalContent>
      </Modal>
      <HStack
        key={campaign.id}
        bg={campaign.archivedAt !== null ? "whiteAlpha.50" : "whiteAlpha.200"}
        w={"100%"}
        borderRadius={"md"}
        p={4}
        columnGap={4}
        spacing={0}
        align={"center"}
        className={"campaign-item"}
        justifyContent={"space-between"}
        borderWidth={1}
        borderColor={campaign.archivedAt !== null ? "whiteAlpha.200" : undefined}
        _hover={{
          bg: "whiteAlpha.300",
        }}
        transition={"background-color 200ms"}
        cursor={"pointer"}
        onClick={() => handleCampaignSelect(campaign.id)}
      >
        <HStack
          w={"100%"}
          spacing={0}
          rowGap={4}
          columnGap={4}
          opacity={campaign.archivedAt !== null ? 0.4 : 1}
          className={"campaign-item-inner"}
          wrap={"wrap"}
        >
          <Image
            src={campaign.image !== null ? `data:image;base64, ${hexToBase64(campaign.image)}` : ""}
            fallbackSrc={ImgNotFound}
            fit={campaign.image !== null ? "contain" : "cover"}
            boxSize={12}
            borderRadius={"md"}
          />
          <VStack w={"30%"} align={"flex-start"} spacing={0}>
            <Text fontSize={"lg"} fontWeight={"medium"} noOfLines={1} title={campaign.title}>
              {campaign.title}
            </Text>
            <Text
              title={campaignDescription()}
              as={"i"}
              pr={1}
              color={"gray.300"}
              fontSize={"sm"}
              noOfLines={1}
              wordBreak={"break-all"}
            >
              {campaign.startDate !== null ? campaign.startDate.toLocaleDateString() : "N/A"} -{" "}
              {campaign.endDate !== null ? campaign.endDate.toLocaleDateString() : "N/A"}
            </Text>
          </VStack>
          <HStack minW={"max-content"} align={"flex-start"} spacing={12}>
            <VStack minW={"max-content"} align={"flex-start"} spacing={0}>
              <Text fontSize={"md"}>Channels</Text>
              <Text color={"gray.300"} fontSize={"sm"}>
                {campaign.channels.length}
              </Text>
            </VStack>
            <VStack minW={"max-content"} align={"flex-start"} spacing={0}>
              <Text fontSize={"md"}>Videos</Text>
              <Text color={"gray.300"} fontSize={"sm"}>
                {campaign.searchCount}
              </Text>
            </VStack>
            <VStack minW={"max-content"} align={"flex-start"} spacing={0}>
              <Text fontSize={"md"}>Video hours</Text>
              <Text color={"gray.300"} fontSize={"sm"}>
                {Math.floor(campaign.searchHours)}
              </Text>
            </VStack>
          </HStack>
        </HStack>
        <HStack w={"max-content"} justifyContent={"flex-end"} minW={"150px"}>
          {getBadgeForStatus()}
          <Box>
            <Menu strategy={"fixed"}>
              <MenuButton
                as={IconButton}
                icon={<MoreOne size={24} />}
                size={"sm"}
                variant={"ghost"}
                disabled={isStatusLoading}
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList>
                {isArchived ? (
                  <MenuItem
                    icon={<InboxOut size={16} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      archiveCampaign(false);
                    }}
                  >
                    Unarchive
                  </MenuItem>
                ) : (
                  <>
                    {campaign.status === "active" && (
                      <MenuItem
                        icon={<PauseOne size={16} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCampaignStatus("paused");
                        }}
                        isDisabled={demoUser}
                      >
                        Pause
                      </MenuItem>
                    )}
                    {campaign.status !== "active" && (
                      <MenuItem
                        icon={<PlayOne theme="filled" size={16} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCampaignStatus("active");
                        }}
                        isDisabled={demoUser}
                      >
                        Resume
                      </MenuItem>
                    )}
                    {campaign.status !== "completed" && (
                      <MenuItem
                        icon={<CheckIcon fontSize={16} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCampaignStatus("completed");
                        }}
                        isDisabled={demoUser}
                      >
                        Mark completed
                      </MenuItem>
                    )}
                    <MenuItem
                      icon={<InboxIn size={16} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        archiveCampaign(true);
                      }}
                      isDisabled={demoUser}
                    >
                      Archive
                    </MenuItem>
                  </>
                )}
                <MenuDivider />
                <MenuItem
                  icon={<EditIcon fontSize={16} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    openCampaignEditModal();
                  }}
                >
                  Edit
                </MenuItem>
                {isAdmin && <MenuItem icon={<HistoryQuery size={16} />}>History</MenuItem>}
                {!demoUser && (
                  <MenuItem
                    icon={<DeleteFive size={16} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      openCampaignDeleteAlert();
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </HStack>
    </>
  );
};
