import { gql } from "@apollo/client";

// Queries
export const PaymentPortalQuery = gql`
  query PaymentPortal($org: String) {
    getPaymentPortal(org: $org) {
      customerPortal
      paymentMethod
    }
  }
`;

export const GetVideosQuery = gql`
  query GetVideos($platform: Platform!) {
    getVideos(platform: $platform) {
      videos {
        id
        title
        channelName
        url
        thumbnailUrl
        duration
        publishedAt
        language
      }
      nextPageCursor
    }
  }
`;

// Mutations
export const ClipRequestMutation = gql`
  mutation ClipRequest($requests: [ClipRequestDataInput!]!) {
    clipRequest(requestsInput: $requests) {
      id
      moment_id
    }
  }
`;

export const SearchMutation = gql`
  mutation StartSearch($searchRequest: SearchRequestInput!) {
    startSearch(searchRequestInput: $searchRequest) {
      id
    }
  }
`;

export const DisconnectTwitchMutation = gql`
  mutation DisconnectTwitch {
    disconnectTwitch {
      accountId
    }
  }
`;
