import { useContext, useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  HStack,
  Divider,
  InputGroup,
  Input,
  InputRightAddon,
  Button,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  Stack,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import PlatformButton from "../PlatformButton";
import { validate as uuidValidate } from "uuid";
import { formatDateForDisplay } from "../../../utils/time";
import { AccessKeysByKindeIdQuery } from "../../../api/access-key";
import { useQuery } from "@apollo/client";
import { AccountContext } from "../../../context/AccountContext";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { Loading } from "../../Loading/Loading";

const CampaignKeys = () => {
  const { account } = useContext(AccountContext);
  const toast = useToast();
  const { token } = useAuthToken();

  const [campaignKey, setCampaignKey] = useState("");
  const [activeKeys, setActiveKeys] = useState<any[]>([]);
  const twitchUser = account.twitch_username;

  const { loading: keysLoading, refetch: fetchKeys } = useQuery(AccessKeysByKindeIdQuery, {
    onCompleted(data) {
      if (data.access_key_with_expiration) {
        setActiveKeys(
          data.access_key_with_expiration.filter((key: any) => {
            return new Date(key.expires_at) >= new Date();
          })
        );
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { account_id: account.id },
  });

  const activateKey = async (key: string) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/backend/key/activate`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key: key }),
        method: "POST",
      });
      if (!response.ok) {
        toast({
          title: "Error activating key",
          description: "Failed to activate key.",
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          position: "bottom",
        });
        throw new Error("Failed to activate key");
      }
      const data = await response.json();
      if (data.activated) {
        fetchKeys();
        toast({
          title: "Key successfully claimed",
          description: `Key ${key} was claimed.`,
          status: "success",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          position: "bottom",
        });
      } else {
        toast({
          title: "Error activating key",
          description: `${data.message}.`,
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error activating key",
        description: "Failed to activate key.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
    }
  };

  const formatCampaignKey = (value: string) => {
    // Remove all non-alphanumeric characters
    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, "");

    // Insert dashes at appropriate indices
    let formattedValue = "";
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i === 8 || i === 12 || i === 16 || i === 20) {
        formattedValue += "-";
      }
      formattedValue += cleanedValue[i];
    }

    return formattedValue.slice(0, 36).toUpperCase();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCampaignKey(event.target.value);
    setCampaignKey(formattedValue);
  };

  const handleKeySubmit = async () => {
    if (uuidValidate(campaignKey)) {
      activateKey(campaignKey);
    } else {
      toast({
        title: "Error activating key",
        description: `Submitted key is invalid.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
      return;
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const renderTableHeader = () => (
    <Tr>
      <Th cursor={"default"}>Campaign</Th>
      {!isMobile && (
        <>
          <Th cursor={"default"}>Key</Th>
          <Th cursor={"default"}>Activated On</Th>
          <Th cursor={"default"}>Valid Until</Th>
        </>
      )}
    </Tr>
  );

  const renderTableRow = (key: any, index: number) => (
    <Tr key={index}>
      <Td>
        <VStack align="start" spacing={1}>
          <Text>{key.key_name}</Text>
          {isMobile && (
            <>
              <Text fontSize="sm">Key: {key.access_key.toUpperCase()}</Text>
              <Text fontSize="sm">
                Activated On: {formatDateForDisplay(new Date(key.claimed_at))}
              </Text>
              <Text fontSize="sm">
                Valid Until: {formatDateForDisplay(new Date(key.expires_at))}
              </Text>
            </>
          )}
        </VStack>
      </Td>
      {!isMobile && (
        <>
          <Td textTransform="uppercase">{key.access_key}</Td>
          <Td>{formatDateForDisplay(new Date(key.claimed_at))}</Td>
          <Td>{formatDateForDisplay(new Date(key.expires_at))}</Td>
        </>
      )}
    </Tr>
  );

  return (
    <Box w="100%" height="100%" alignItems="flex-start">
      <VStack alignItems="flex-start" spacing={12} pt={6}>
        <HStack
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          spacing={0}
        >
          <VStack alignItems="flex-start">
            <Heading as="h1" fontSize="3xl">
              Campaigns
            </Heading>
            <Text color="gray.300">
              Activate campaign keys and monitor your participation in ongoing campaigns.
            </Text>
          </VStack>
        </HStack>

        <Divider />

        <VStack alignItems="flex-start" w="100%" spacing={6}>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={6}
            wrap="wrap"
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Text fontSize="lg">Connect to Twitch</Text>
              <Text color="gray.300" fontSize="sm" mb={4}>
                In order to validate your participation in a campaign, Rivr must first connect to
                your Twitch account.
              </Text>
            </VStack>
            <PlatformButton
              platform="twitch"
              username={twitchUser}
              redirectUrl="/account?tab=campaigns"
            />
          </HStack>
        </VStack>

        <VStack
          alignItems="flex-start"
          w="100%"
          spacing={6}
          opacity={twitchUser !== null ? 1 : 0.66}
          cursor={twitchUser !== null ? "initial" : "not-allowed"}
        >
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={6}
            wrap="wrap"
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Text fontSize="lg">Campaign key</Text>
              <Text color="gray.300" fontSize="sm" mb={4}>
                Enter the provided campaign key.
              </Text>
            </VStack>
            <InputGroup maxW="520px" w="100%" ml="0 !important">
              <Input
                className={"amp-unmask"}
                value={campaignKey}
                onChange={handleInputChange}
                placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                textTransform="uppercase"
                isDisabled={twitchUser === null}
              />
              <InputRightAddon w="fit-content" p={0}>
                <Button
                  minW="fit-content"
                  colorScheme="green"
                  borderStartRadius={0}
                  isDisabled={campaignKey.length !== 36 || twitchUser === null}
                  isLoading={false}
                  loadingText="Activating"
                  onClick={handleKeySubmit}
                >
                  Submit
                </Button>
              </InputRightAddon>
            </InputGroup>
          </HStack>
        </VStack>

        {keysLoading ? (
          <Loading variant={"large"} />
        ) : (
          <>
            <Divider />
            <VStack alignItems="flex-start" w="100%">
              <Stack
                w="100%"
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
                alignItems="flex-start"
                gap={6}
                wrap="wrap"
                pb={4}
              >
                <VStack alignItems="flex-start" spacing={1}>
                  <Text fontSize="lg">Active campaigns</Text>
                  <Text color="gray.300" fontSize="sm" mb={4}>
                    Campaigns you are currently participating in.
                  </Text>
                </VStack>
              </Stack>

              {activeKeys.length > 0 ? (
                <TableContainer w="100%">
                  <Table size="sm" variant="striped">
                    <Thead>{renderTableHeader()}</Thead>
                    <Tbody>
                      {activeKeys
                        .sort((a: any, b: any) => {
                          return (
                            new Date(a.claimed_at).valueOf() - new Date(b.claimed_at).valueOf()
                          );
                        })
                        .map((key: any, index: number) => renderTableRow(key, index))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert borderRadius={"md"} status={"info"} variant={"left-accent"}>
                  <AlertIcon />
                  <Box>
                    <AlertTitle>No active campaigns</AlertTitle>
                    <AlertDescription>
                      Activate valid campaign keys to join campaigns.
                    </AlertDescription>
                  </Box>
                </Alert>
              )}
            </VStack>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default CampaignKeys;
