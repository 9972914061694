import { Button, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";

import PlanCard from "../PlanCard/PlanCard";
import MockSearchbar from "./MockSearchbar";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { MessageOne } from "@icon-park/react";

const ConvertSearchHeader = () => {
  const openChatwoot = () => {
    (window as any).$chatwoot?.toggle();
  };

  return (
    <HStack
      w={"100%"}
      maxW={"1300px"}
      justify={"center"}
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <VStack
        w={"100%"}
        p={4}
        alignItems={{ base: "center", md: "flex-start" }}
        textAlign={{ base: "center", md: "left" }}
        spacing={6}
      >
        <VStack w={"100%"} alignItems={{ base: "center", md: "flex-start" }} spacing={4}>
          <Heading fontSize={"4xl"} sx={{ textWrap: "balance" }}>
            Thanks for trying Rivr!
          </Heading>
          <Text color={"gray.300"} sx={{ textWrap: "pretty" }} fontSize={"lg"}>
            Want to analyze more videos? Join today and lock in exclusive early access pricing.
          </Text>
        </VStack>
        <MockSearchbar />
        <VStack
          w={"100%"}
          align={"flex-start"}
          spacing={3}
          pt={{ base: 0, md: 8 }}
          alignItems={{ base: "center", md: "flex-start" }}
        >
          <Text color={"gray.300"}>Need help or have a question?</Text>
          <HStack>
            <Button
              size={"sm"}
              colorScheme={"blue"}
              leftIcon={<MessageOne />}
              onClick={openChatwoot}
            >
              Chat now
            </Button>
            <Button
              size={"sm"}
              colorScheme={"purple"}
              rightIcon={<ExternalLinkIcon />}
              as={"a"}
              href={"https://discord.gg/WTkWJKzyQK"}
              target={"_blank"}
            >
              Join Discord
            </Button>
          </HStack>
        </VStack>
      </VStack>
      <Flex p={4} w={"100%"} justify={"center"}>
        <PlanCard
          planType="EA"
          planAvailable={true}
          showSubscribe={true}
          subscriptionExists={false}
        />
      </Flex>
    </HStack>
  );
};

export default ConvertSearchHeader;
