import { AspectRatio, Box, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { RocketOne } from "@icon-park/react";
import { useNavigate } from "react-router-dom";
import DiscordLogo from "../../assets/discord-logo.svg";
import { AccountURLParams, RivrLocations } from "../../models/navigation";

const NewUserSearchHeader = () => {
  const navigate = useNavigate();

  return (
    <HStack
      w={"100%"}
      maxW={"1300px"}
      justify={"center"}
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <VStack w={"100%"} p={4} alignItems={{ base: "center", md: "flex-start" }} spacing={6}>
        <Heading fontSize={"4xl"}>Welcome to Rivr early access</Heading>
        <VStack w={"100%"} alignItems={"flex-start"} maxW={"600px"}>
          <HStack
            as={"a"}
            w={"full"}
            h={"fit-content"}
            p={4}
            borderWidth={1}
            justifyContent={"space-between"}
            href={"https://discord.gg/WTkWJKzyQK"}
            target={"_blank"}
            flexWrap={"wrap-reverse"}
            rowGap={4}
            borderRadius={"md"}
            bg={"whiteAlpha.100"}
            transition={"200ms"}
            _hover={{
              bg: "blue.800",
              borderColor: "blue.300",
              transform: "translateY(-1px)",
            }}
          >
            <VStack align={"flex-start"} mr={2} spacing={0}>
              <Text fontSize={"md"} fontWeight={"semibold"}>
                Join Discord
              </Text>
              <Text
                fontWeight={"normal"}
                fontSize={"sm"}
                sx={{ textWrap: "pretty" }}
                textAlign={"left"}
                color={"gray.300"}
              >
                Rivr is in closed early access and launching soon. Stay connected to learn more.
              </Text>
            </VStack>
            <Box pr={0.5}>
              <Image src={DiscordLogo} boxSize={6} />
            </Box>
          </HStack>
          <HStack
            as={"button"}
            w={"full"}
            h={"fit-content"}
            p={4}
            borderWidth={1}
            justifyContent={"space-between"}
            onClick={() =>
              navigate(`${RivrLocations.Account}?${AccountURLParams.SelectedTab}=campaigns`)
            }
            flexWrap={"wrap-reverse"}
            rowGap={4}
            borderRadius={"md"}
            bg={"whiteAlpha.100"}
            transition={"200ms"}
            _hover={{
              bg: "green.800",
              borderColor: "green.300",
              transform: "translateY(-1px)",
            }}
          >
            <VStack align={"flex-start"} mr={2} spacing={0}>
              <Text fontSize={"md"} fontWeight={"semibold"}>
                Join a campaign
              </Text>
              <Text
                fontWeight={"normal"}
                fontSize={"sm"}
                sx={{ textWrap: "pretty" }}
                textAlign={"left"}
                color={"gray.300"}
              >
                Have a campaign key? Click here to join a campaign.
              </Text>
            </VStack>
            <RocketOne size={28} strokeWidth={2.5} />
          </HStack>
        </VStack>
      </VStack>
      <AspectRatio ratio={16 / 9.45} w={"100%"} maxW={"600px"}>
        <Box p={4}>
          <iframe
            src="https://www.youtube.com/embed/ltw_X1p3mbY?si=mihlmVI3in28plhH"
            title="Rivr tutorial"
            style={{
              border: "1px solid #4A5568",
              borderRadius: "0.375rem",
              width: "100%",
              height: "100%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Box>
      </AspectRatio>
    </HStack>
  );
};

export default NewUserSearchHeader;
