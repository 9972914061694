import { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from "react";
import {
  VStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  HStack,
  Input,
  useDisclosure,
  Text,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
  InputGroup,
  InputRightElement,
  Kbd,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  Box,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon, SettingsIcon } from "@chakra-ui/icons";
import { Save, Search } from "@icon-park/react";
import { MentionsSearch, mentionsSearchFromQueryResult } from "../../../../models/mentionsSearch";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams } from "../../../../models/navigation";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CreateMentionsSearchMutation,
  MentionsSearchesQuery,
} from "../../../../api/mentions-search";
import MentionsSearchManager from "./MentionsSearchManager";
import { RivrMention, speakerMentionFromQueryResult } from "../../../../models/mention";
import { SpeakerMentionsQuery } from "../../../../api/mention";

interface Props {
  keywords: string[];
  setKeywords: (keywords: string[]) => void;
  setMentions: (mentions: RivrMention[]) => void;
  setFailedSearch: (failed: boolean) => void;
  setQueryTime: (time: number) => void;
  setMentionsLoading: (loading: boolean) => void;
}

const DiscoverMentionsSearch = ({
  keywords,
  setKeywords,
  setMentions,
  setFailedSearch,
  setQueryTime,
  setMentionsLoading,
}: Props) => {
  const toast = useToast();

  const {
    isOpen: isKeywordsSelectOpen,
    onOpen: onKeywordsSelectOpen,
    onClose: onKeywordsSelectClose,
  } = useDisclosure();
  const {
    isOpen: isSaveAlertOpen,
    onOpen: onSaveAlertOpen,
    onClose: onSaveAlertClose,
  } = useDisclosure();
  const {
    isOpen: isManageModalOpen,
    onOpen: onManageModalOpen,
    onClose: onManageModalClose,
  } = useDisclosure();

  const previousSearch = localStorage.getItem("previousSearch");

  const cancelRef = useRef<HTMLButtonElement>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [mentionsSearches, setMentionsSearches] = useState<MentionsSearch[]>([]);
  const [keywordSetTitle, setKeywordSetTitle] = useState<string>("");
  const [showSaveError, setShowSaveError] = useState<boolean>(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";

  const [getMentions] = useLazyQuery(SpeakerMentionsQuery, {
    onCompleted(data) {
      if (data.speakerMentions === null) {
        setFailedSearch(true);
      } else {
        setMentions(data.speakerMentions.map((m: any) => speakerMentionFromQueryResult(m)));
      }
      setIsSubmitButtonLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsSubmitButtonLoading(false);
      setFailedSearch(true);
      toast({
        title: "Search failed",
        description: "There was an error encountered while performing the search.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { refetch: fetchMentionsSearches } = useQuery(MentionsSearchesQuery, {
    onCompleted(data) {
      setMentionsSearches(
        data.mentions_search.map((search: any) => mentionsSearchFromQueryResult(search))
      );
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { organization_id: selectedOrg },
  });

  const [mentionsSearchCreateAPI] = useMutation(CreateMentionsSearchMutation, {
    onCompleted() {
      toast({
        title: "New keyword set created",
        description: `${keywordSetTitle} was successfully created.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchMentionsSearches();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      toast({
        title: "Keyword set not created",
        description: "There was an error encountered while creating the new keyword set.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleNewKeywords = (newKeywords: string[]) => {
    const result = [...keywords];
    for (const kw of newKeywords) {
      if (kw !== "" && !keywords.includes(kw)) {
        result.push(kw);
      }
    }
    setKeywords([...result]);
    return result;
  };

  const handleRemoveKeyword = (index: number) => {
    setKeywords(keywords.filter((_, i) => i !== index));
  };

  const handleClearKeywords = () => {
    setKeywords([]);
    localStorage.removeItem("previousSearch");
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Allow for quoted strings
    if (e.key === '"') {
      if (searchInput.includes('"')) {
        e.preventDefault();
        handleNewKeywords([searchInput + '"']);
        setSearchInput("");
      }
      if (searchInput !== "") {
        e.preventDefault();
      }
    }
    if (e.key === " " || e.key === "Tab") {
      // Allow for quoted strings
      if (searchInput.includes('"')) {
        if (e.key === "Tab") e.preventDefault();
        return;
      }
      e.preventDefault();
      const trimmed = searchInput.trim();
      const values = trimmed.split(" ");
      handleNewKeywords(values);
      setSearchInput("");
    }
    if (e.key === "Backspace" && searchInput === "") {
      handleRemoveKeyword(keywords.length - 1);
      setSearchInput("");
    }
    if (e.key === "Enter" && (searchInput !== "" || keywords.length > 0)) {
      setMentionsLoading(true);
      handleSearch();
    }
  };

  const buildSearchString = () => {
    if (searchInput !== "") {
      const trimmed = searchInput.trim();
      const values = trimmed.split(" ");
      const result = handleNewKeywords(values);
      setSearchInput("");
      return result.join(" OR ");
    }
    return keywords.join(" OR ");
  };

  const handleSearch = async () => {
    setMentions([]);
    setFailedSearch(false);
    setIsSubmitButtonLoading(true);
    const query = buildSearchString();
    const queryStart = Date.now();
    await getMentions({ variables: { query: query, campaign_id: selectedCampaign } });
    setQueryTime((Date.now() - queryStart) / 1000);
    setMentionsLoading(false);
    localStorage.setItem("previousSearch", query);
  };

  const handleSave = () => {
    if (
      mentionsSearches.map((s) => s.title.toLowerCase()).includes(keywordSetTitle.toLowerCase())
    ) {
      setShowSaveError(true);
      toast({
        title: "Keyword set not saved",
        description: "A set with that title already exists.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const searchCreateVariables = {
        organization_id: selectedOrg,
        title: keywordSetTitle,
        search: buildSearchString(),
        created_at: new Date(Date.now()),
      };
      mentionsSearchCreateAPI({ variables: searchCreateVariables });

      setShowSaveError(false);
      setKeywordSetTitle("");
      onSaveAlertClose();
    }
  };

  useEffect(() => {
    if (keywords.length === 0) {
      document.getElementById("keyword-input")?.focus();
    } else {
      document.getElementById("keyword-input-multiple")?.focus();
    }
  }, [keywords]);

  useEffect(() => {
    const fetchMentions = async () => {
      if (previousSearch !== null && previousSearch !== "") {
        const queryStart = Date.now();
        await getMentions({ variables: { query: previousSearch, campaign_id: selectedCampaign } });
        setQueryTime((Date.now() - queryStart) / 1000);
        setKeywords(previousSearch.split(" OR "));
      }
      setMentionsLoading(false);
    };

    setMentionsLoading(true);
    fetchMentions();
  }, []);

  return (
    <>
      <AlertDialog
        isOpen={isSaveAlertOpen}
        onClose={onSaveAlertClose}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <VStack w={"100%"} spacing={0} justify={"flex-start"} align={"flex-start"}>
                <Text>Save keywords</Text>
                <Text fontSize={"sm"} color={"gray.300"} fontWeight={"normal"}>
                  Title your set of keywords.
                </Text>
              </VStack>
            </AlertDialogHeader>
            <AlertDialogBody>
              <Input
                placeholder={"Keyword set title"}
                value={keywordSetTitle}
                onChange={(e) => setKeywordSetTitle(e.target.value)}
                isInvalid={showSaveError}
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant={"ghost"}
                onClick={() => {
                  setShowSaveError(false);
                  setKeywordSetTitle("");
                  onSaveAlertClose();
                }}
              >
                Close
              </Button>
              <Button
                isDisabled={keywordSetTitle.trim() === ""}
                colorScheme={"green"}
                ml={2}
                onClick={handleSave}
              >
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <MentionsSearchManager
        mentionsSearches={mentionsSearches}
        fetchMentionsSearches={fetchMentionsSearches}
        isModalOpen={isManageModalOpen}
        onModalClose={onManageModalClose}
      />

      <HStack w={"100%"} alignItems={"flex-start"}>
        <Box>
          <Popover
            onClose={onKeywordsSelectClose}
            onOpen={onKeywordsSelectOpen}
            isOpen={isKeywordsSelectOpen}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <Button
                rightIcon={<ChevronDownIcon boxSize={5} />}
                size={"sm"}
                fontWeight={"normal"}
                minW={"fit-content"}
              >
                Saved keywords
              </Button>
            </PopoverTrigger>
            <PopoverContent w={"fit-content"}>
              <PopoverBody p={0} maxH={"calc(8 * 2.5rem)"} overflowY={"auto"}>
                {mentionsSearches.length > 0 && (
                  <VStack w={"100%"} spacing={0} px={0} py={2}>
                    {mentionsSearches
                      .sort((a, b) => {
                        return new Date(a.updatedAt).valueOf() < new Date(b.updatedAt).valueOf()
                          ? 1
                          : -1;
                      })
                      .map((search, index) => (
                        <Button
                          key={index}
                          variant={"ghost"}
                          w={"full"}
                          justifyContent={"flex-start"}
                          borderRadius={0}
                          fontWeight={"normal"}
                          onClick={() => {
                            setKeywords(search.keywords);
                            onKeywordsSelectClose();
                          }}
                        >
                          {search.title}
                        </Button>
                      ))}
                  </VStack>
                )}
              </PopoverBody>
              <PopoverFooter borderTopWidth={mentionsSearches.length > 0 ? "thin" : 0} p={0}>
                <Button
                  variant={"ghost"}
                  leftIcon={<SettingsIcon />}
                  fontWeight={"normal"}
                  size={"sm"}
                  w={"full"}
                  justifyContent={"flex-start"}
                  borderRadius={0}
                  onClick={() => {
                    onManageModalOpen();
                  }}
                  my={2}
                >
                  Manage keywords
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Box>
        <FormControl>
          <InputGroup
            size={"sm"}
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            cursor={"text"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              width={"100%"}
              borderRadius={"md"}
              borderWidth={1}
              outline={"2px solid transparent"}
              outlineOffset={"2px"}
              minH={8}
              pl={2}
              pr={24}
              py={0.5}
              transition={"200ms"}
              _focusWithin={{
                borderWidth: 1,
                borderColor: "blue.300",
                boxShadow: "0 0 0 1px #63B3ED",
              }}
            >
              {keywords.length > 0 && (
                <Wrap spacing={0} w={"100%"}>
                  <WrapItem key={"clear"}>
                    <Button
                      borderRadius={"full"}
                      variant={"outline"}
                      colorScheme={"red"}
                      cursor={"pointer"}
                      onClick={() => handleClearKeywords()}
                      size={"xs"}
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      px={2}
                      h={"1.3rem"}
                      mr={0.5}
                      ml={0.5}
                      alignSelf={"center"}
                    >
                      Clear
                    </Button>
                  </WrapItem>
                  {keywords.map((keyword, index) => (
                    <WrapItem key={index}>
                      <Tag
                        borderRadius={"full"}
                        variant={"solid"}
                        colorScheme="gray"
                        _hover={{ bg: "whiteAlpha.500" }}
                        minW={"fit-content"}
                        size={"sm"}
                        fontSize={"sm"}
                        mx={0.5}
                        my={0.5}
                      >
                        <TagLabel>{keyword}</TagLabel>
                        <TagCloseButton onClick={() => handleRemoveKeyword(index)} />
                      </Tag>
                    </WrapItem>
                  ))}
                  <Input
                    pl={1}
                    size={"sm"}
                    value={searchInput}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    placeholder={"..."}
                    borderRadius={"md"}
                    flex={"1"}
                    variant={"unstyled"}
                    id={"keyword-input-multiple"}
                    autoComplete={"off"}
                  />
                </Wrap>
              )}
              {keywords.length < 1 && (
                <Input
                  size={"sm"}
                  value={searchInput}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  placeholder={"Add keywords"}
                  borderRadius={"md"}
                  flex={"1"}
                  variant={"unstyled"}
                  id={"keyword-input"}
                  autoComplete={"off"}
                />
              )}
            </Box>
            <InputRightElement
              justifyContent={"flex-end"}
              mr={2}
              w={24}
              h={"100%"}
              pointerEvents={"none"}
            >
              <HStack spacing={1}>
                <Kbd>Space</Kbd>
                <Kbd>Tab</Kbd>
              </HStack>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button
          leftIcon={<Search />}
          minW={"fit-content"}
          colorScheme={"green"}
          zIndex={1}
          size={"sm"}
          onClick={() => {
            setMentionsLoading(true);
            handleSearch();
          }}
          isDisabled={keywords.length < 1 && searchInput === ""}
          isLoading={isSubmitButtonLoading}
        >
          Search
        </Button>
        <Button
          leftIcon={<Save />}
          size={"sm"}
          minW={"fit-content"}
          colorScheme={"blue"}
          onClick={onSaveAlertOpen}
          isDisabled={keywords.length < 1}
        >
          Save
        </Button>
      </HStack>
    </>
  );
};

export default DiscoverMentionsSearch;
