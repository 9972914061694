import {
  Flex,
  Text,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Badge,
  Wrap,
  Tooltip,
  Box,
  Tag,
  HStack,
} from "@chakra-ui/react";
import { MoreOne, ScreenRotation, Undo } from "@icon-park/react";
import { secondsToHMSDuration } from "../../../utils/time";
import { CheckIcon, CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import "./MomentItem.css";
import { DeleteConfirmation } from "../DeleteConfirmation/DeleteConfirmation";
import { EditMoment } from "../../EditMoment/EditMoment";
import { useEffect, useRef, useState } from "react";
import { Moment, RecommendedMoment, recommendedMomentSettings } from "../../../models/moment";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import * as amplitude from "@amplitude/analytics-browser";

export type UserMomentInputs = {
  kind: "UserMoment";
  moment: Moment;
  downloadDisabled: boolean;
  downloadCallback: () => void;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  applyActionSet: () => void;
  verticalReformat: () => void;
};

export type RecommendedMomentInputs = {
  kind: "RecommendedMoment";
  moment: RecommendedMoment;
  accept: () => void;
  toggle: () => void;
};

export type MomentInputs = UserMomentInputs | RecommendedMomentInputs;

type Props = {
  inputs: MomentInputs;
  checkCallback: (id: string) => void;
  focusedCallback: (id: string | null) => void;
  isChecked: boolean;
  isFocused: boolean;
};

export const MomentItem = (props: Props) => {
  const { inputs, checkCallback, focusedCallback, isChecked, isFocused } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [showEditMoment, setShowEditMoment] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const data = inputs.moment;
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  const selectMoment = () => {
    if (inputs.kind === "UserMoment" || !inputs.moment.rejected)
      focusedCallback(isFocused ? null : data.id);
  };

  const toggle = () => {
    if (inputs.kind === "RecommendedMoment") {
      if (isFocused) focusedCallback(null);
      inputs.toggle();
    }
  };

  const sortRecommendedMomentAnnotations = (
    a: { [key: string]: any },
    b: { [key: string]: any }
  ) => {
    // Sort the annotation to the front if it matches the moment type,
    // or sort by signal value
    if (inputs.kind === "RecommendedMoment") {
      return a[0] === inputs.moment.kind ? -1 : b[0] === inputs.moment.kind ? 1 : b[1] - a[1];
    } else {
      return 0;
    }
  };

  const scrollIntoView = (div: HTMLDivElement) => {
    if (!div.parentElement) return;
    const rect = div.getBoundingClientRect();
    const parentRect = div.parentElement.getBoundingClientRect();
    if (rect.bottom < parentRect.top || rect.top > parentRect.bottom) div.scrollIntoView();
  };

  const recommendedMomentBadges = () => {
    // Filter out chat intensity and sort annotations by relevance and value
    const recommendedAnnotations = Object.entries(inputs.moment.annotations)
      .filter(([signal, _]) => signal !== "Chat intensity")
      .sort(sortRecommendedMomentAnnotations);
    const topAnnotation = recommendedAnnotations.shift();
    if (topAnnotation === undefined) return null;
    return (
      <Wrap zIndex={0} spacing={1} my={1}>
        {/* Display the top annotation */}
        <Tooltip label={(recommendedMomentSettings as any)[topAnnotation[0]].title}>
          <Tag
            colorScheme={(recommendedMomentSettings as any)[topAnnotation[0]].colorScheme}
            variant={"subtle"}
            size={"xl"}
            py={0.5}
            borderRadius={"sm"}
            minW={"24px"}
            justifyContent={"center"}
          >
            {(recommendedMomentSettings as any)[topAnnotation[0]].icon}
          </Tag>
        </Tooltip>
        {/* Display any more annotations that meet the filter threshold */}
        {recommendedAnnotations
          .filter(([_, value]) => value > 0.5)
          .map(([signal], index) => (
            <Tooltip label={(recommendedMomentSettings as any)[signal].title} key={index}>
              <Tag
                colorScheme={(recommendedMomentSettings as any)[signal].colorScheme}
                variant={"subtle"}
                size={"xl"}
                py={0.5}
                borderRadius={"sm"}
                minW={"24px"}
                justifyContent={"center"}
              >
                {(recommendedMomentSettings as any)[signal].icon}
              </Tag>
            </Tooltip>
          ))}
      </Wrap>
    );
  };

  useEffect(() => {
    if (isFocused && ref.current) scrollIntoView(ref.current);
  }, [isFocused]);

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Flex
      borderRadius={"md"}
      w={"100%"}
      _hover={{ bg: "gray.600" }}
      bg={"gray.700"}
      pl={4}
      pr={3}
      className={`result-item-${data.id} momentItem ${isFocused ? "momentItem-focused" : ""}`}
      pos={"relative"}
      ref={ref}
      borderWidth={1}
      borderColor={isFocused ? "transparent" : ""}
      borderStyle={"inset"}
    >
      {isFocused && (
        <Box
          pos={"absolute"}
          h={"100%"}
          w={"100%"}
          top={0}
          left={0}
          borderWidth={2}
          borderColor={"teal.300"}
          borderRadius={"md"}
        />
      )}
      {inputs.kind !== "RecommendedMoment" && (
        <Checkbox
          className={`inp-result-item-checkbox inp-result-item-${data.id}`}
          w={4}
          mr={4}
          colorScheme={"teal"}
          size={"md"}
          onChange={() => checkCallback(data.id)}
          isChecked={isChecked}
          display={"inherit"}
        />
      )}
      <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Flex flexDirection={"column"} minW={"150px"} w={"100%"} onClick={selectMoment} py={2}>
          <Text fontSize={"md"} fontWeight={"medium"} noOfLines={1} title={data.title} mr={2}>
            {data.title}
          </Text>
          <Flex flexDirection={"row"}>
            <Text color={"gray.300"} fontSize={"xs"}>
              {secondsToHMSDuration(data.start_time)} - {secondsToHMSDuration(data.end_time)}
            </Text>
          </Flex>
          {data.tags.length > 0 && (
            <Wrap spacing={1} my={1}>
              {data.tags.slice(0, 3).map((label, index) => (
                <Badge
                  key={index}
                  width={"fit-content"}
                  colorScheme={"green"}
                  variant={"subtle"}
                  fontWeight={"medium"}
                  letterSpacing={0.5}
                  minH={"22px"}
                  fontSize={"sm"}
                  size={"xs"}
                >
                  {label}
                </Badge>
              ))}
              {data.tags.length > 3 && (
                <Tooltip label={data.tags.slice(3).join(", ")}>
                  <Badge
                    width={"fit-content"}
                    colorScheme={"green"}
                    variant={"subtle"}
                    fontWeight={"medium"}
                    letterSpacing={0.5}
                    minH={"22px"}
                    fontSize={"sm"}
                    size={"xs"}
                  >
                    +{data.tags.length - 3}
                  </Badge>
                </Tooltip>
              )}
            </Wrap>
          )}
          {inputs.kind === "RecommendedMoment" && recommendedMomentBadges()}
        </Flex>
        {inputs.kind === "UserMoment" ? (
          <HStack>
            <Tooltip label={"Download"}>
              <IconButton
                aria-label={"Download"}
                className={"btn-po-download moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                disabled={inputs.downloadDisabled}
                onClick={() => {
                  inputs.downloadCallback();
                  handleAmplitudeClick("Download Single Moment");
                }}
                icon={<DownloadIcon boxSize={4} />}
              />
            </Tooltip>
            <Tooltip label={"Reformat to Vertical"}>
              <IconButton
                aria-label={"Reformat to Vertical"}
                className={"btn-po-reformat moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                disabled={inputs.downloadDisabled}
                onClick={() => {
                  handleAmplitudeClick("Start Reformat to Vertical");
                  inputs.verticalReformat();
                }}
                icon={<ScreenRotation size={18} style={{ transform: "scaleY(-1)rotate(75deg)" }} />}
              />
            </Tooltip>
            <Box>
              <Menu isLazy>
                <MenuButton
                  className={"moment-action"}
                  as={IconButton}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  icon={<MoreOne size={24} />}
                />
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      handleAmplitudeClick("Edit Moment Clicked");
                      setShowEditMoment(true);
                    }}
                  >
                    Edit
                  </MenuItem>
                  {!demoUser && (
                    <MenuItem
                      onClick={() => {
                        setShowDeleteConfirmation(true);
                        handleAmplitudeClick("Delete Moment Clicked");
                      }}
                    >
                      Delete
                    </MenuItem>
                  )}
                </MenuList>
                {showEditMoment && (
                  <EditMoment
                    confirm={(info) => {
                      inputs.updateMoment(inputs.moment, info);
                      handleAmplitudeClick("Moment Edit Confirmed");
                    }}
                    onClose={() => setShowEditMoment(false)}
                    confirmButtonText="Confirm"
                    initialData={data}
                  />
                )}
                <DeleteConfirmation
                  confirm={() => {
                    inputs.deleteMoments([data.id]);
                    handleAmplitudeClick("Moment Delete Confirmed");
                  }}
                  isOpen={showDeleteConfirmation}
                  onClose={() => setShowDeleteConfirmation(false)}
                  headerText={"Delete Moment"}
                  bodyText={"Are you sure you want to delete this Moment?"}
                />
              </Menu>
            </Box>
          </HStack>
        ) : (
          <>
            <Tooltip label={"Add to My Moments"}>
              <IconButton
                aria-label={"Accept"}
                className={"btn-po-accept moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  inputs.accept();
                  handleAmplitudeClick("Accept Recommended Moment");
                }}
                icon={<CheckIcon />}
                display={!inputs.moment.rejected ? "static" : "none"}
              />
            </Tooltip>
            <Tooltip label={!inputs.moment.rejected ? "Reject Moment" : "Undo"}>
              <IconButton
                aria-label={!inputs.moment.rejected ? "Reject" : "Undo"}
                className={"btn-po-toggle moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                ml={2}
                onClick={() => {
                  toggle();
                  if (!inputs.moment.rejected) {
                    handleAmplitudeClick("Reject Recommended Moment");
                  } else {
                    handleAmplitudeClick("Undo Recommended Moment Rejection");
                  }
                }}
                icon={!inputs.moment.rejected ? <CloseIcon boxSize={3} /> : <Undo size={16} />}
              />
            </Tooltip>
          </>
        )}
      </Flex>
      {inputs.kind === "RecommendedMoment" && inputs.moment.rejected && (
        <Box
          pos={"absolute"}
          borderRadius={"md"}
          w={"100%"}
          h={"100%"}
          bg={"blackAlpha.500"}
          top={0}
          left={0}
          pointerEvents={"none"}
        />
      )}
    </Flex>
  );
};
