import { useRef, useState } from "react";
import {
  Text,
  IconButton,
  Badge,
  Box,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image,
  VStack,
  Tooltip,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  // Spinner,
  Progress,
  Checkbox,
} from "@chakra-ui/react";
import {
  BackOne,
  Check,
  Eyes,
  Heartbeat,
  InboxIn,
  InboxOut,
  MoreOne,
  Square,
  Videocamera,
} from "@icon-park/react";
import { DeleteIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  DeleteSearchMutation,
  SetSearchArchiveStatusMutation,
  SetSearchWorkflowMutation,
  StopSearchMutation,
} from "../../../api/search";
import { originTwitch, originYouTube } from "../../../models/urls";
import TwitchLogo from "../../../assets/twitch-logo.svg";
import YouTubeLogo from "../../../assets/youtube-logo.svg";
import ImgNotFound from "../../../assets/image-not-found.png";
import * as amplitude from "@amplitude/analytics-browser";
import { formatDateForDisplay } from "../../../utils/time";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Cookies from "js-cookie";
import { ResultsURLParams, RivrLocations, updateURLParams } from "../../../models/navigation";
import { RivrSearch } from "../../../models/rivrSearch";
import "../SearchHistoryItem.css";

interface Props {
  search: RivrSearch;
  onDelete: () => void;
  isSelectionMode: boolean;
  isSelected: boolean;
  onSelect: (id: string, selected: boolean) => void;
}

export const SearchHistoryItem = ({
  search,
  onDelete,
  isSelectionMode,
  isSelected,
  onSelect,
}: Props) => {
  const [searchParams] = useSearchParams();

  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const cancelRef = useRef(null);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;
  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const isArchived = search.archivedAt !== undefined;

  const viewLink = `${RivrLocations.Results}/${search.id}?${updateURLParams(
    searchParams.toString(),
    [
      [ResultsURLParams.SelectedMoment, ""],
      [ResultsURLParams.SelectedTimestamp, ""],
      [ResultsURLParams.SelectedPanel, ""],
    ]
  )}`;

  const confirmDelete = () => {
    setIsDeleteLoading(true);
    deleteSearchAPI({
      variables: { id: search.id, deleted_at: new Date() },
    });
  };

  const [stopSearchAPI] = useMutation(StopSearchMutation, {
    onCompleted() {
      onDelete();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [deleteSearchAPI] = useMutation(DeleteSearchMutation, {
    onCompleted() {
      setIsDeleteLoading(false);
      onDeleteAlertClose();
      onDelete();
      amplitude.track("History Item(s) Deleted");
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const [archiveSearchAPI] = useMutation(SetSearchArchiveStatusMutation, {
    onCompleted() {
      onDelete();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [setWorkflowStatusAPI] = useMutation(SetSearchWorkflowMutation, {
    onCompleted() {
      onDelete();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const setSearchWorkflowStatus = (status: string | null) => {
    setIsStatusLoading(true);
    setWorkflowStatusAPI({
      variables: { id: search.id, status },
    });
  };

  const stopSearch = () => {
    setIsStatusLoading(true);
    stopSearchAPI({ variables: { id: search.id } });
  };

  const archiveSearch = (date: Date | null) => {
    setIsStatusLoading(true);
    archiveSearchAPI({ variables: { id: search.id, date } });
  };

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  /* const getBadgeForStatus = () => {
    if (isArchived) {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="gray"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Archived
        </Badge>
      );
    }
    if (search.status === "in-progress") {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="yellow"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Analyzing... {search.searchProgress ? `${search.searchProgress}%` : ""}
          <Spinner size="xs" ml={1} mt={0.5} />
        </Badge>
      );
    } else if (search.status === "error") {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="red"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Error
        </Badge>
      );
    } else {
      switch (search.workflowStatus) {
        case "completed":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="blue"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Completed
            </Badge>
          );
        case "reviewing":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="purple"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Reviewing
            </Badge>
          );
        default:
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="green"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Analyzed
            </Badge>
          );
      }
    }
  }; */

  return (
    <>
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this video and its Moments? This cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onDeleteAlertClose}
                colorScheme="gray"
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button isLoading={isDeleteLoading} colorScheme="red" onClick={confirmDelete} ml={2}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack
        spacing={"0"}
        alignItems={"start"}
        className={"search-item-wrapper"}
        borderWidth={1}
        borderRadius={"md"}
        h={"fit-content"}
        pos={"relative"}
        _hover={{
          ".search-item-details": { bg: "whiteAlpha.300", transition: "background-color 200ms" },
        }}
      >
        {isSelectionMode && (
          <Checkbox
            justifyContent={"center"}
            w={"100%"}
            h={"100%"}
            pos={"absolute"}
            isChecked={isSelected}
            onChange={(e) => onSelect(search.id, e.target.checked)}
            zIndex={1}
            colorScheme={"red"}
            bg={"blackAlpha.600"}
            borderRadius={"inherit"}
            size={"lg"}
            className={"delete-checkbox"}
          />
        )}
        <Box pos={"relative"} w={"100%"} h={"auto"} opacity={isArchived ? 0.4 : 1}>
          <Link to={viewLink} onClick={() => handleAmplitudeClick("History Card Clicked")}>
            <Image
              src={search.metadata?.thumbnailUrl || ImgNotFound}
              borderTopRadius={"md"}
              w={"full"}
              h={"auto"}
              fallbackSrc={ImgNotFound}
              userSelect={"none"}
            />
          </Link>
        </Box>
        <VStack
          w={"100%"}
          spacing={0}
          mt={"-46px !important"}
          pos={"relative"}
          pointerEvents={"none"}
        >
          <HStack
            justifyContent={"start"}
            w={"100%"}
            bg={`linear-gradient(to top, #171923 25%, transparent 100%)`}
            p={3}
            className={"search-item-status"}
            pointerEvents={"none"}
          >
            <HStack alignItems={"end"} pointerEvents={"none"}>
              {/* {getBadgeForStatus()} */}

              {search.videoType === "VOD" && (
                <Badge
                  colorScheme={"blackAlpha"}
                  bg={"blackAlpha.800"}
                  variant={"solid"}
                  position={"absolute"}
                  right={3}
                  py={0.5}
                  opacity={isArchived ? 0.4 : 1}
                >
                  {search.videoDuration}
                </Badge>
              )}
              {(!search.status || search.status !== "in-progress") && (
                <Box h={"30px"} bg={"gray.900"} pointerEvents={"none"} />
              )}
            </HStack>
          </HStack>
          {search.videoType === "VOD" && search.status === "in-progress" && (
            <VStack w="100%" spacing={-1}>
              <Progress
                size={"sm"}
                colorScheme={"yellow"}
                isIndeterminate={search.searchProgress ? false : true}
                value={search.searchProgress ?? 0}
                w={"100%"}
                className={"search-item-progress"}
              />
              <Progress
                size={"sm"}
                colorScheme={"yellow"}
                isIndeterminate
                w={"100%"}
                mt={"-8px !important"}
              />
            </VStack>
          )}
          {search.videoType === "LIVE" && search.status === "in-progress" && (
            <Progress value={60} size={"sm"} colorScheme={"red"} isIndeterminate w={"100%"} />
          )}
        </VStack>
        <HStack
          justifyContent={"space-between"}
          w={"100%"}
          bg={isArchived ? "whiteAlpha.50" : "whiteAlpha.200"}
          p={3}
          className={"search-item-details"}
          borderBottomRadius={"md"}
          h={"100%"}
          alignItems={"start"}
          transition={"background-color 200ms"}
          pointerEvents={isSelectionMode ? "none" : "auto"}
        >
          <VStack
            alignItems={"start"}
            alignContent={"start"}
            justifyContent={"center"}
            spacing={1.5}
            w={"100%"}
          >
            <HStack
              alignItems={"center"}
              justifyContent={"start"}
              className={"search-item-details-icon-title"}
              w={"100%"}
              spacing={1}
              opacity={isArchived ? 0.4 : 1}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {search.originType === originTwitch ? (
                <Image src={TwitchLogo} alt={"Twitch Logo"} title={"Twitch"} h={"18px"} mr={1} />
              ) : search.originType === originYouTube ? (
                <Image src={YouTubeLogo} alt={"YouTube Logo"} title={"YouTube"} h={"18px"} mr={1} />
              ) : (
                <></>
              )}
              <Link to={viewLink} onClick={() => handleAmplitudeClick("History Card Clicked")}>
                <Text
                  className={`lnk-to-result-${search.id}`}
                  fontSize={"sm"}
                  noOfLines={1}
                  wordBreak={"break-all"}
                  title={search.videoTitle || "Error"}
                >
                  {search.videoTitle || "Error"}
                </Text>
              </Link>
            </HStack>
            <HStack w={"100%"} spacing={0} cursor={"default"} justifyContent={"space-between"}>
              <HStack spacing={0} opacity={isArchived ? 0.4 : 1}>
                <Tooltip label={"Date published"}>
                  <HStack
                    alignItems={"center"}
                    className={"search-item-details-date"}
                    spacing={1.5}
                    pr={3}
                  >
                    <Videocamera size={"18px"} />
                    <Text fontSize={"sm"} sx={{ textWrap: "nowrap" }}>
                      {search.metadata
                        ? formatDateForDisplay(search.metadata.publishedAt)
                        : "Not available"}
                    </Text>
                  </HStack>
                </Tooltip>
                {search.momentsCount && search.momentsCount > 0 && (
                  <Tooltip
                    label={`${search.momentsCount} Moment${
                      search.momentsCount === 1 ? "" : "s"
                    } created`}
                  >
                    <HStack alignItems={"center"} spacing={1.5} pr={3}>
                      <Heartbeat size={"18px"} theme={"outline"} />
                      <Text fontSize={"sm"} sx={{ textWrap: "nowrap" }}>
                        {search.momentsCount}
                      </Text>
                    </HStack>
                  </Tooltip>
                )}
              </HStack>
              <Menu strategy={"fixed"} placement={"right"}>
                <MenuButton
                  as={IconButton}
                  aria-label={"More options"}
                  icon={<MoreOne size={"20px"} />}
                  size={"xs"}
                  variant={"ghost"}
                  disabled={isStatusLoading || isDeleteLoading || isSelectionMode}
                />
                <MenuList>
                  {isAdmin &&
                    ["in-progress", "error"].includes(search.status) === false &&
                    !isArchived && (
                      <>
                        {search.workflowStatus !== undefined && (
                          <MenuItem
                            icon={<BackOne />}
                            onClick={() => {
                              setSearchWorkflowStatus(null);
                              handleAmplitudeClick("WFS: Reset to Analyzed");
                            }}
                          >
                            Reset to Analyzed
                          </MenuItem>
                        )}
                        {search.workflowStatus !== "reviewing" && (
                          <MenuItem
                            icon={<Eyes />}
                            onClick={() => {
                              setSearchWorkflowStatus("reviewing");
                              handleAmplitudeClick("WFS: Set Reviewing");
                            }}
                          >
                            Mark Reviewing
                          </MenuItem>
                        )}
                        {search.workflowStatus !== "completed" && (
                          <MenuItem
                            icon={<Check />}
                            onClick={() => {
                              setSearchWorkflowStatus("completed");
                              handleAmplitudeClick("WFS: Set Completed");
                            }}
                          >
                            Mark Completed
                          </MenuItem>
                        )}
                        <MenuDivider />
                      </>
                    )}
                  <MenuItem
                    as={"a"}
                    href={search.url}
                    target={"_blank"}
                    icon={<ExternalLinkIcon />}
                    onClick={() => handleAmplitudeClick(`View on ${search.originType} Clicked`)}
                  >
                    View on {search.originType === originTwitch ? "Twitch" : "YouTube"}
                  </MenuItem>

                  {!demoUser && (
                    <>
                      <MenuDivider />
                      {search.status === "in-progress" ? (
                        <MenuItem icon={<Square theme="filled" />} onClick={stopSearch}>
                          Stop analyzing
                        </MenuItem>
                      ) : (
                        <>
                          {isAdmin && (
                            <MenuItem
                              icon={!isArchived ? <InboxIn /> : <InboxOut />}
                              onClick={() => {
                                archiveSearch(!isArchived ? new Date() : null);
                                handleAmplitudeClick(
                                  !isArchived ? "WFS: Archived" : "WFS: Unarchived"
                                );
                              }}
                            >
                              {!isArchived ? "Archive" : "Unarchive"}
                            </MenuItem>
                          )}
                          <MenuItem
                            icon={<DeleteIcon />}
                            onClick={() => {
                              onDeleteAlertOpen();
                              handleAmplitudeClick("Single History Item Delete Dialog");
                            }}
                          >
                            Delete
                          </MenuItem>
                        </>
                      )}
                    </>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
};
