import {
  Text,
  Badge,
  Box,
  Button,
  Image,
  VStack,
  Tooltip,
  HStack,
  Progress,
  useToast,
  Grid,
  Spinner,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { PlayOne, Redo, Square, Videocamera } from "@icon-park/react";
import { useApolloClient, useMutation } from "@apollo/client";
import { DeleteSearchMutation, SearchByIDQuery, StopSearchMutation } from "../../api/search";
import { originTwitch, originYouTube } from "../../models/urls";
import TwitchLogo from "../../assets/twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";
import ImgNotFound from "../../assets/image-not-found.png";
import { formatDateForDisplay } from "../../utils/time";
import Cookies from "js-cookie";
import { RivrSearch } from "../../models/rivrSearch";
import { useState, useRef } from "react";
import { SearchMutation } from "../../api/actions";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  search: RivrSearch;
  refreshSearches: () => void;
  hasSearchAccess: boolean;
  userId: string;
  onBillingOpen: () => void;
  isActiveSearchLimitReached: boolean;
}

export const ActiveSearchItem = ({
  search,
  refreshSearches,
  hasSearchAccess,
  userId,
  onBillingOpen,
  isActiveSearchLimitReached,
}: Props) => {
  const toast = useToast();
  const client = useApolloClient();

  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const viewLink = search.url;

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [isStopConfirmDialogOpen, setIsStopConfirmDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [insertSearchAPI] = useMutation(SearchMutation, {
    onCompleted() {
      setIsButtonLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => console.log("Error:", err.extensions.code));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsButtonLoading(false);
    },
  });

  const [stopSearchAPI] = useMutation(StopSearchMutation, {
    onCompleted() {
      refreshSearches();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const [deleteSearchAPI] = useMutation(DeleteSearchMutation, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => console.log("Error:", err.extensions.code));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const stopSearch = async () => {
    try {
      await stopSearchAPI({ variables: { id: search.id } });
    } catch (error) {
      console.error("Error stopping search:", error);
      toast({
        title: "Error stopping video",
        description: "Failed to stop analyzing the video.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
    }
  };

  const processPlatformVideo = async (url: string) => {
    try {
      await insertSearchAPI({
        variables: {
          searchRequest: {
            url: url,
            userId: userId,
          },
        },
      });

      refreshSearches();
    } catch (error) {
      console.error(error);
      toast({
        title: "Error analyzing video",
        description: "Failed to analyze the video.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
      setIsButtonLoading(false);
    }
  };

  const reprocessSearch = async (searchId: string) => {
    try {
      // Step 1: Fetch the full search data before modifying it
      const { data } = await client.query({
        query: SearchByIDQuery,
        variables: { id: searchId },
        fetchPolicy: "no-cache",
      });

      const fullSearch = data.search_by_pk;

      // Step 2: Mark the search as deleted
      await deleteSearchAPI({
        variables: { id: searchId, deleted_at: new Date().toISOString() },
      });

      // Step 3: Insert a new search with the same data, ensuring non-null Boolean values
      await insertSearchAPI({
        variables: {
          searchRequest: {
            url: fullSearch.url,
            campaignId: fullSearch.campaign_id,
            userId: fullSearch.user_id,
          },
        },
      });

      // Refresh the searches
      refreshSearches();
    } catch (error) {
      console.error(error);
      toast({
        title: "Error reanalzying video",
        description: "Failed to reanalyze the video.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
      setIsButtonLoading(false);
    }
  };

  const onAnalyze = async (url: string) => {
    setIsButtonLoading(true);
    if (!hasSearchAccess) {
      onBillingOpen();
      setIsButtonLoading(false);
      return;
    }

    if (!isAdmin && isActiveSearchLimitReached) {
      toast({
        title: "Limit reached",
        description: "You have reached your active analyzing limit. Wait for analysis to complete.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      setIsButtonLoading(false);
      return;
    }

    await processPlatformVideo(url);
  };

  const onReprocess = async (searchId: string) => {
    setIsButtonLoading(true);
    if (!hasSearchAccess) {
      onBillingOpen();
      setIsButtonLoading(false);
      return;
    }

    if (!isAdmin && isActiveSearchLimitReached) {
      toast({
        title: "Limit reached",
        description: "You have reached your active analyzing limit. Wait for analysis to complete.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      setIsButtonLoading(false);
      return;
    }

    await reprocessSearch(searchId);
  };

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  const getBadgeForStatus = () => {
    if (search.status === "error") {
      return (
        <Badge
          display={"flex"}
          borderRadius={"sm"}
          colorScheme={"red"}
          py={0.5}
          variant={"solid"}
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Error
        </Badge>
      );
    } else if (search.status === "stopped") {
      return (
        <Badge
          display={"flex"}
          borderRadius={"sm"}
          colorScheme={"red"}
          py={0.5}
          variant={"solid"}
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Stopped
        </Badge>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <VStack
        spacing={0}
        alignItems={"start"}
        className={"search-item-wrapper"}
        borderWidth={1}
        borderRadius={"md"}
        h={"fit-content"}
        pos={"relative"}
        _hover={{
          ".search-item-details": { bg: "whiteAlpha.300", transition: "background-color 200ms" },
        }}
      >
        <Box as={"a"} href={viewLink} position={"relative"} w={"100%"} h={"auto"} target={"_blank"}>
          <Image
            src={search.metadata?.thumbnailUrl || ImgNotFound}
            borderTopRadius={"md"}
            w={"1920px"}
            h={"auto"}
            fallbackSrc={ImgNotFound}
            userSelect={"none"}
          />
        </Box>
        <VStack w={"100%"} spacing={0} mt={"-48px !important"} pointerEvents={"none"}>
          <HStack
            pos={"relative"}
            justifyContent={"start"}
            w={"100%"}
            bg={`linear-gradient(to top, #171923 25%, transparent 100%)`}
            p={3}
            className={"search-item-status"}
            pointerEvents={"none"}
            h={12}
          >
            {getBadgeForStatus()}

            {search.videoType === "VOD" && (
              <Badge
                colorScheme={"blackAlpha"}
                bg={"blackAlpha.800"}
                variant={"solid"}
                pos={"absolute"}
                right={3}
                py={0.5}
              >
                {search.videoDuration}
              </Badge>
            )}
          </HStack>
        </VStack>
        <HStack
          justifyContent={"space-between"}
          w={"100%"}
          bg={"whiteAlpha.200"}
          p={3}
          className={"search-item-details"}
          borderBottomRadius={"md"}
          h={"100%"}
          alignItems={"start"}
          transition={"background-color 200ms"}
        >
          <VStack
            alignItems={"start"}
            alignContent={"start"}
            justifyContent={"center"}
            spacing={1.5}
            w={"100%"}
          >
            <HStack
              alignItems={"center"}
              justifyContent={"start"}
              className={"search-item-details-icon-title"}
              w={"100%"}
              spacing={1}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {search.originType === originTwitch ? (
                <Image src={TwitchLogo} alt={"Twitch Logo"} title={"Twitch"} h={"18px"} mr={1} />
              ) : search.originType === originYouTube ? (
                <Image src={YouTubeLogo} alt={"YouTube Logo"} title={"YouTube"} h={"18px"} mr={1} />
              ) : (
                <></>
              )}
              <Box as={"a"} href={viewLink} target={"_blank"}>
                <Text
                  className={`lnk-to-result-${search.id}`}
                  fontSize={"sm"}
                  noOfLines={1}
                  wordBreak={"break-all"}
                  title={search.videoTitle}
                >
                  {search.videoTitle}
                </Text>
              </Box>
            </HStack>
            <HStack
              w={"100%"}
              cursor={"default"}
              flexWrap={"wrap"}
              justifyContent={"space-between"}
              pb={1}
            >
              <HStack
                alignItems={"center"}
                className={"search-item-details-date"}
                spacing={1.5}
                pr={3}
                flex={1}
              >
                <Videocamera size={"18px"} />
                {search.metadata && (
                  <Tooltip label={"Date published"}>
                    <Text fontSize={"sm"} sx={{ textWrap: "nowrap" }}>
                      {formatDateForDisplay(search.metadata.publishedAt)}
                    </Text>
                  </Tooltip>
                )}
              </HStack>
            </HStack>
            {search.status === "metadata" ? (
              <Button
                w={"full"}
                leftIcon={<PlayOne theme={"filled"} />}
                variant={"solid"}
                size={"sm"}
                colorScheme={"green"}
                isLoading={isButtonLoading}
                onClick={() => {
                  onAnalyze(search.url);
                  handleAmplitudeClick("Analyze Button Clicked");
                }}
              >
                Analyze
              </Button>
            ) : search.status === "in-progress" ? (
              <Box
                w={"full"}
                h={8}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {isHovered && search.searchProgress < 100 ? (
                  <Button
                    w={"full"}
                    leftIcon={<Square theme={"filled"} size={12} />}
                    variant={"solid"}
                    size={"sm"}
                    colorScheme={"red"}
                    onClick={() => {
                      setIsStopConfirmDialogOpen(true);
                      handleAmplitudeClick("Stop Analyzing Button Clicked");
                    }}
                  >
                    Stop analyzing
                  </Button>
                ) : (
                  <Grid
                    w={"100%"}
                    templateColumns={"1fr"}
                    position={"relative"}
                    borderRadius={"md"}
                  >
                    <Text
                      position={"absolute"}
                      zIndex={1}
                      w={"100%"}
                      textAlign={"center"}
                      verticalAlign={"middle"}
                      h={8}
                      lineHeight={8}
                      fontSize={"sm"}
                      color={"blackAlpha.900"}
                      fontWeight={"semibold"}
                      userSelect={"none"}
                    >
                      {search.searchProgress === 100 ? (
                        <>
                          Finalizing <Spinner size={"xs"} />
                        </>
                      ) : (
                        <>Analyzing... {search.searchProgress ? `${search.searchProgress}%` : ""}</>
                      )}
                    </Text>
                    <Progress
                      isIndeterminate
                      w={"100%"}
                      h={8}
                      colorScheme={"yellow"}
                      position={"absolute"}
                      bg={"transparent"}
                      top={0}
                      borderRadius={"md"}
                      zIndex={0}
                      pointerEvents={"none"}
                    />
                    <Progress
                      w={"100%"}
                      h={8}
                      colorScheme={"yellow"}
                      value={search.searchProgress}
                      bg={"yellow.500"}
                      borderRadius={"md"}
                      zIndex={-1}
                      min={0}
                      max={100}
                      pointerEvents={"none"}
                    />
                  </Grid>
                )}
              </Box>
            ) : ["error", "stopped"].includes(search.status) ? (
              <Button
                w={"full"}
                leftIcon={<Redo />}
                variant={"solid"}
                size={"sm"}
                colorScheme={"blue"}
                isLoading={isButtonLoading}
                onClick={() => {
                  onReprocess(search.id);
                  handleAmplitudeClick("Reanalyze Button Clicked");
                }}
              >
                Reanalyze
              </Button>
            ) : (
              <Button
                w={"full"}
                leftIcon={<Square size={"10px"} theme={"filled"} />}
                variant={"solid"}
                size={"sm"}
                colorScheme={"red"}
                onClick={() => {
                  setIsStopConfirmDialogOpen(true);
                  handleAmplitudeClick("Stop Analyzing Button Clicked");
                }}
              >
                Stop analyzing
              </Button>
            )}
          </VStack>
        </HStack>
      </VStack>
      <AlertDialog
        isOpen={isStopConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsStopConfirmDialogOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
              Stop analyzing
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to stop analyzing this video?</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setIsStopConfirmDialogOpen(false);
                  handleAmplitudeClick("Confirm Stop Analyzing");
                }}
                colorScheme={"gray"}
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button
                colorScheme={"red"}
                onClick={() => {
                  stopSearch();
                  setIsStopConfirmDialogOpen(false);
                  handleAmplitudeClick("Cancel Stop Analyzing");
                }}
                ml={2}
              >
                Stop
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
